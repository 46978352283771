import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

class ColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Amount',
          data: this.props.chartData,
        },
      ],
      options: {
        title: {
          text: 'Your payments, by month',
          align: 'center',
          margin: 12,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#263238',
          },
        },
        chart: {
          height: 300,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: this.props.colorsList,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
          // formatter: (value) => `£${value}`,
        },
        legend: {
          show: false,
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter: (value) => `£${value}`,
          },
        },
      },
    };
  }

  render() {
    return (
      <Box boxShadow={2} className="nintyPercentWidth curvyCorners whiteBg">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={300}
        />
        <div className="paddingBottomOne">
          <span className="marginLeftTwo">
            <span className="chartLegend greenBg" />
            <span className="marginLeftTwo">Payments</span>
          </span>
          {/* <span className="marginLeftTwo">
            <span className="chartLegend grayBg" />
            <span className="marginLeftTwo">Expected Payments</span>
          </span> */}
        </div>
      </Box>
    );
  }
}

export default ColumnChart;

ColumnChart.propTypes = {
  colorsList: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(PropTypes.object),
};

ColumnChart.defaultProps = {
  colorsList: ['#009b4a'],
  chartData: [],
};
