import React from 'react';
import PropTypes from 'prop-types';

const BalanceSquare = (props) => (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
  <div
    className="balanceSquare"
    style={{ backgroundColor: props.bgColor }}
    role="button"
    onClick={props.clickEvent}
    onKeyPress={() => {}}
  >
    <div className="balanceSquareInnerText">{props.insideText}</div>
  </div>
);

export default BalanceSquare;

BalanceSquare.propTypes = {
  insideText: PropTypes.element,
  bgColor: PropTypes.string,
  clickEvent: PropTypes.func,
};

BalanceSquare.defaultProps = {
  insideText: '',
  bgColor: '#c70100',
  clickEvent: () => {},
};
