import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 99,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    // alignSelf: 'flex-end',
    textAlign: 'center',
    marginTop: '12px',
  },
}));

const CustomTitlebar = (props) => {
  const classes = useStyles();

  // Back button click handled
  const handleBackButtonClick = () => {
    props.backClick();
  };

  // Menu button click handled
  const handleMenuButtonClick = () => {
    props.menuClick();
  };

  return (
    <div>
      <AppBar position="static" className="blackTitlebar">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={`${classes.menuButton} menuIcon dontShowOnDesktop`}
            color="inherit"
            aria-label="open drawer"
            style={{ display: props.showMenuBtn ? '' : 'none' }}
            onClick={handleMenuButtonClick}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            edge="start"
            className={`${classes.menuButton} menuIcon`}
            color="inherit"
            aria-label="open drawer"
            style={{ display: props.showBackBtn ? '' : 'none' }}
            onClick={handleBackButtonClick}
          >
            <ArrowBackIcon />
          </IconButton>

          <div className={classes.title} variant="h5">
            {props.titleText}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CustomTitlebar;
CustomTitlebar.propTypes = {
  titleText: PropTypes.element,
  showMenuBtn: PropTypes.bool,
  showBackBtn: PropTypes.bool,
  menuClick: PropTypes.func,
  backClick: PropTypes.func,
};

CustomTitlebar.defaultProps = {
  titleText: '',
  showMenuBtn: false,
  showBackBtn: false,
  menuClick: () => false,
  backClick: () => false,
};
