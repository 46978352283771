import * as actionType from '../actions/actionTypes';
import updateObject from '../utility';

// Set Intial State
const intialState = {
  accountDetail: null,
  error: false,
};

// Reducer which return state according it's action
const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionType.GET_ACCOUNT_DETAIL_SUCCESS:
      return updateObject(state, { accountDetail: action.accountDetail });
    case actionType.GET_ACCOUNT_DETAIL_FAIL:
      return updateObject(state, { error: true });
    case actionType.LOGOUT:
      return intialState;
    default:
      return state;
  }
};

export default reducer;
