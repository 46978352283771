import React, { Component } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';

import DisplayField from './DisplayField';

class CustomAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const accountInfoListitems = this.props.displayList.map(
      (singleInfoObj, index) => (
        <DisplayField
          // eslint-disable-next-line react/no-array-index-key
          key={index + 1}
          label={singleInfoObj.label}
          value={singleInfoObj.value}
        />
        // eslint-disable-next-line comma-dangle
      )
    );

    return (
      <Accordion className="customAccordianContainer marginTop">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
          className="accordianTitle"
        >
          <Typography>{this.props.blockTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <List component="nav" aria-label="main mailbox folders" fullwidth>
              {accountInfoListitems}
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default CustomAccordion;
CustomAccordion.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  displayList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CustomAccordion.defaultProps = {};
