import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import CustomInputField from '../../hoc/CustomInputField';
import SubTitle from '../../hoc/SubTitle';
import CustomButton from '../../hoc/CustomButton';
import BackButton from '../../hoc/BackButton';
import { valdaUrl } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import {
  handleResponseCode, checkEmailisValid, showAlert, handleException,
} from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class ForgotPasswordOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToLogin: false,
      navigateToForgotPasswordTwo: false,
      email: '', // 'test@example.com',
      errorEmail: false,
      emailErrorMessage: '',
    };
  }

  // handle submit button
  handleSubmit = () => {
    // validation of email
    if (this.state.email === '') {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "Email can't be blank.",
      });
      return;
    }
    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: 'Email is not valid',
      });
      return;
    }

    // Api call for forgotPassword
    const url = `${valdaUrl}forgotPassword`;
    const apiData = {
      email: this.state.email,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          this.setState({
            navigateToForgotPasswordTwo: true,
          });
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Back button which takes to Login page
  handleBackBtn = () => {
    this.setState({
      navigateToLogin: true,
    });
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: '',
    });
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToForgotPasswordTwo) {
      return <Redirect to="/forgotpasswordtwo" />;
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="FORGOTTEN PASSWORD" />
          <BackButton btnClick={this.handleBackBtn} />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <SubTitle titleText="Forgotten Password" />
          <div className="centerText marginTop">
            Enter your email address below and we will send you instruction mail
            on how to change your password.
          </div>
          <CustomInputField
            fieldName="Email"
            fieldType="email"
            fieldValue={this.state.email}
            isError={this.state.errorEmail}
            errorMessage={this.state.emailErrorMessage}
            textChange={this.handleEmailChange}
          />
          <CustomButton
            btnName="SUBMIT"
            btnClick={this.handleSubmit}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </Grid>
      </div>
    );
  }
}

export default ForgotPasswordOne;
