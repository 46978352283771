import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import SubTitle from '../../hoc/SubTitle';
import CustomButton from '../../hoc/CustomButton';
import BackButton from '../../hoc/BackButton';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class ForgotPasswordTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToLogin: false,
      navigateToForgotPasswordOne: false,
    };
  }

  handleLogin = () => {
    this.setState({
      navigateToLogin: true,
    });
  };

  handleBackBtn = () => {
    this.setState({
      navigateToForgotPasswordOne: true,
    });
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToForgotPasswordOne) {
      return <Redirect to="/forgotpasswordone" />;
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="SUCCESS" />
          <BackButton btnClick={this.handleBackBtn} />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <SubTitle titleText="We have sent you an email" />
          <div className="centerText marginTop">
            Please check your email to confirm change of password.
          </div>
          <CustomButton
            btnName="LOGIN"
            btnClick={this.handleLogin}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </Grid>
      </div>
    );
  }
}

export default ForgotPasswordTwo;
