import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import SubTitle from '../../hoc/SubTitle';

// Failure Callback page present when payment session is failed
class FailureCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToHome: false,
    };
  }

  // Handle Make Payment which redirect to Home Page
  handleMakePayment = () => {
    this.setState({
      navigateToHome: true,
    });
  };

  render() {
    if (this.state.navigateToHome) {
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: { validated: true },
          }}
        />
      );
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="MAKE A PAYMENT" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <SubTitle titleText="Payment Declined" />
          <div className="centerText marginTop">
            Unfortunately your payment has been declined. For alternative ways
            to pay or use a different card please{' '}
            <a href="tel:03303904510" className="noLinkText greenText">
              contact us here
            </a>
            .
          </div>

          <CustomButton
            btnName="MAKE PAYMENT"
            btnClick={this.handleMakePayment}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </Grid>
      </div>
    );
  }
}

export default FailureCallback;
