import React, { Component } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Redirect } from "react-router-dom";

import "./AccountInformation.css";
import CustomButton from "../../hoc/CustomButton";
import { pinCode } from "../../constants";
import { logScreenView } from "../../utility";

class ResetPIN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToCreatePin: false
    };
  }

  handleResetPIN = () => {
    localStorage.removeItem(pinCode);
    this.setState({
      navigateToCreatePin: true,
    });
  };


  render() {
    logScreenView("Reset PIN");
    if (this.state.navigateToCreatePin) {
      return <Redirect to="/createpin" />;
    }
    if(localStorage.getItem(pinCode) !== null) {
      return (
        <div className="marginTop">
          <CustomButton
            btnName="Reset PIN"
            btnClick={this.handleResetPIN}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </div>
      );
    } else {
      return null;
    }
    
  }
}

export default ResetPIN;
