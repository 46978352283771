import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducers/home';

// Axios Interceptor Request
axios.interceptors.request.use(
  (request) => {
    // set headers like Token
    // request.headers = {
    //   'x-api-key': 'Of7fpu69it5rYNFoKwz8g1aDsRHU7wfL4YSSARAM'
    // };
    console.log(request);
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// Axios Interceptor Response
axios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// Redux Store Set Up
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// Handle event of internet connection come back
const handleOnLine = () => {
  window.open('#/home', '_self');
};

// Handle event of internet connection goes out
const handleOffLine = () => {
  window.open('#/noconnection', '_self');
};

const renderReactDom = () => {
  // Set event of internet connection come back
  window.addEventListener('online', handleOnLine);
  // Set event of internet connection goes out
  window.addEventListener('offline', handleOffLine);

  ReactDOM.render(app, document.getElementById('root'));
};

if (window.cordova) {
  // If app is running in mobile device as native app it will render react app on deviceready event.
  document.addEventListener(
    'deviceready',
    () => {
      window.FirebasePlugin.setAnalyticsCollectionEnabled(true); // Enables analytics data collection
      // window.FirebasePlugin.setUserId('6638310889');
      // window.cordova.plugins.firebase.analytics.setUserId('6638310889');
      renderReactDom();
    },
    false
  );
} else {
  // If app is running in browser it will render react app normally.
  renderReactDom();
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
