import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import TextField from '@material-ui/core/TextField';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';

import {
  accountID,
  seaglassURL,
  valdaUrl,
  accountBalance,
  debitCreditText,
  token,
} from '../../constants';
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import {
  handleResponseCode,
  showAlert,
  handleException,
  getElecOrGasMeterData,
  logScreenView,
  logEvent
} from '../../utility';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import LineChart from '../../hoc/LineChart';
import HHBarChart from '../../hoc/HHBarChart';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import './Usage.css';
import 'react-datepicker/dist/react-datepicker.css';

class Usage extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const oneMonthAgo = currentDate.setMonth(currentDate.getMonth() - 1);
    this.state = {
      sitesList: [],
      selectedMeterpoint: '',
      revenueList: [],
      earliestDate: new Date(),
      hhChartData: [],
      siteEffectiveFrom: '',
      chartDataActuals: [],
      chartDataEstimates: [],
      startDate: new Date(oneMonthAgo),
      endDate: new Date(),
      today: new Date(),
      segmentValue: 'day',
      hhDisabledStatus: false
    };
  }

  componentDidMount() {
    console.log('STARTING componentDidMount');
    registerLocale('en-GB', enGB);
    // Api call for meterPoint
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}/meterpoints`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data && response.response.data.length > 0) {
            console.log('Site Page');
            this.setState({
              sitesList: response.response.data,
              selectedMeterpoint: response.response.data[0],
            });
            this.getDailyRevenuesForSite();
            this.setSitesData(response.response.data);
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Api call for dailyrevenues Sites
  getDailyRevenuesForSite = () => {
    console.log('starting getDailyRevenuesForSite');
    const meterpointId = this.state.selectedMeterpoint.id;
    const metersiteId = this.state.selectedMeterpoint.siteid;
    const energyType = this.state.selectedMeterpoint.utility;
    // eslint-disable-next-line
    if(energyType == "Electricity") {
      this.setState({
        hhDisabledStatus: false,
      });
    } else {
      this.setState({
        hhDisabledStatus: true,
        segmentValue: "day",
      });
    }
    if (meterpointId) {
      let url = `${seaglassURL}sgBill/meterpoints/${meterpointId}/dailyrevenues`;
      const products_url = `${seaglassURL}sgCore/accounts/${localStorage.getItem(
        accountID
      )}/products`;
      POSTSeaglass(url)
        .then((response) => {
          console.log(response);
          if (handleResponseCode(response)) {
            if (response.response.data) {
              console.log('Site Page');
              this.setState({
                revenueList: response.response.data,
              });
              POSTSeaglass(products_url)
                .then((products_response) => {
                  console.log(products_response);
                  if (handleResponseCode(products_response)) {
                    if (products_response.response.data) {
                      products_response.response.data.forEach((product) => {
                        if (product.siteid === metersiteId) {
                          if (product.effectivefrom !== null) {
                            this.setState({
                              siteEffectiveFrom: new Date(
                                product.effectivefrom
                              ),
                            });
                          }
                        }
                      });
                    }
                  } else if (products_response && products_response.message) {
                    showAlert('Error', products_response.message);
                  }
                })
                .catch((error) => {
                  handleException(error);
                });
              this.filterRevenueData(response.response.data);
            }
          } else if (response && response.message) {
            showAlert('Error', response.message);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    }
  };

  // Filter revenue data from daily revenue
  filterRevenueData = (revenueList) => {
    const monthYearList = [];
    const revenueArr = [];
    let date = '';
    let earliestDate = new Date();
    function isIterable(value) {
      return Symbol.iterator in Object(value);
    }
    if (isIterable(revenueList)) {
      console.log('Looping through the revenuelist');
      console.log(revenueList);
      revenueList.forEach((revenue) => {
        if (revenue.consumption !== null) {
          // console.log(`Non-null consumption value of ${revenue.consumption}`);
          date = new Date(revenue.settlementdate);
          if (date < earliestDate) {
            earliestDate = date;
            console.log(`Changed earliest date to ${earliestDate}`);
          }
          if (
            !monthYearList.find(
              (dateObj) =>
                // eslint-disable-next-line implicit-arrow-linebreak
                dateObj.month === date.getMonth() &&
                dateObj.year === date.getFullYear()
            )
          ) {
            monthYearList.push({
              id: revenue.id,
              month: date.getMonth(),
              year: date.getFullYear(),
            });
          }

          date.getFullYear();

          revenueArr.push({
            consumption: revenue.consumption,
            readingqualityid: revenue.readingqualityid,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            day: date.getDate(),
          });
        } else {
          console.log('Null value for consumption');
        }
      });
    } else {
      console.log("Didnt loop through the revenuelist");
      console.log(revenueList);
    }
    console.log('Earliest date was ' + earliestDate);
    console.log('Site allocated to account at ' + this.state.siteEffectiveFrom);
    if (this.state.siteEffectiveFrom > earliestDate) {
      earliestDate = this.state.siteEffectiveFrom;
    }

    // console.log('monthYearList: ', monthYearList);
    console.log('revenueArr: ', revenueArr);
    if (monthYearList && monthYearList.length) {
      const lastMonthYear = monthYearList[monthYearList.length - 1];
      this.setState({
        selectedMonthYear: `${lastMonthYear.month} ${lastMonthYear.year}`,
        monthYearList,
        revenueList: revenueArr,
        earliestDate,
      });
      setTimeout(() => {
        this.setChartData();
      }, 300);
    }
  };

  // User change Month-Year from dropdown
  handleMonthYearChange = (event) => {
    this.setState({ selectedMonthYear: event.target.value });
    setTimeout(() => {
      this.setChartData();
    }, 300);
  };

  // User change Site from dropdown
  handleSiteChange = (event) => {
    const selectedSiteIndex = this.state.sitesList.findIndex(
      (site) => site.id.toString() === event.target.value.toString()
    );

    // eslint-disable-next-line react/no-access-state-in-setstate
    const selectedSite = this.state.sitesList[selectedSiteIndex];

    this.setState({
      selectedMeterpoint: selectedSite,
    });

    setTimeout(() => {
      this.getDailyRevenuesForSite();
    }, 1000);
  };

  handleSegmentChange = (segmentValue) => {
    segmentValue = segmentValue.target.value.toString();
    // eslint-disable-next-line
    this.state.segmentValue = segmentValue;
    setTimeout(() => {
      this.setChartData();
    }, 1000);
  };

  // Set chart Data
  setChartData = () => {
    console.log('starting setChartData');
    const chartDataArrActuals = [];
    const chartDataArrEstimates = [];
    const chartDatahhArr = [];
    // Depending on the segment chosen, different logic will need to be applied
    if (this.state.segmentValue === 'day') {
      this.state.revenueList.forEach((revenue) => {
        const revenueDate = new Date(
          revenue.year,
          revenue.month - 1,
          revenue.day
        );
        if (
          revenueDate >= this.state.startDate &&
          revenueDate <= this.state.endDate
        ) {
          if (revenue.readingqualityid > 1) {
            chartDataArrActuals.push({
              x: `${revenue.day}-${revenue.month}-${revenue.year}`,
              y: revenue.consumption,
            });
          } else {
            if (revenue.consumption > 0) {
              chartDataArrEstimates.push({
                x: `${revenue.day}-${revenue.month}-${revenue.year}`,
                y: revenue.consumption,
              });
            }
          }
        }
      });
      // Calculate if the chartDataArrEstimates array has any values. If it does then any days that don't exist will need to be sustituted with null values so the graph renders correctly
      if (chartDataArrEstimates.length > 0) {
        chartDataArrActuals.forEach((actual) => {
          // Check the x value to see if one exists in the estimates array
          if (chartDataArrEstimates.map((o) => o.x).indexOf(actual.x) === -1) {
            // Doesn't exist, so need to create a fake value
            chartDataArrEstimates.push({
              x: actual.x,
              y: null,
            });
          }
        });
      }
      // Now do the same to match up the other array, so one doesn't have more than the other
      if (chartDataArrEstimates.length > chartDataArrActuals.length) {
        chartDataArrEstimates.forEach((estimate) => {
          // Check the x value to see if one exists in the actuals array
          if (chartDataArrActuals.map((o) => o.x).indexOf(estimate.x) === -1) {
            // Doesn't exist, so need to create a fake value
            chartDataArrActuals.push({
              x: estimate.x,
              y: null,
            });
          }
        });
      }
    } else if (this.state.segmentValue === 'month') {
      // Same as above, but add each item to the month
      this.state.revenueList.forEach((revenue) => {
        const revenueDate = new Date(
          revenue.year,
          revenue.month - 1,
          revenue.day
        );
        if (
          revenueDate >= this.state.startDate &&
          revenueDate <= this.state.endDate
        ) {
          let found = false;
          if (revenue.readingqualityid > 1) {
            chartDataArrActuals.forEach((item) => {
              if (item.x === `${revenue.month}-${revenue.year}`) {
                found = true;
                item.y += revenue.consumption;
              }
            });
            if (!found) {
              chartDataArrActuals.push({
                x: `${revenue.month}-${revenue.year}`,
                y: revenue.consumption,
              });
            }
          } else {
            if (revenue.consumption > 0) {
              chartDataArrEstimates.forEach((item) => {
                if (item.x === `${revenue.month}-${revenue.year}`) {
                  found = true;
                  item.y += revenue.consumption;
                }
              });
              if (!found) {
                chartDataArrEstimates.push({
                  x: `${revenue.month}-${revenue.year}`,
                  y: revenue.consumption,
                });
              }
            }
          }
        }
      });
       // Calculate if the chartDataArrEstimates array has any values. If it does then any days that don't exist will need to be sustituted with null values so the graph renders correctly
      if (chartDataArrEstimates.length > 0) {
        chartDataArrActuals.forEach((actual) => {
          // Check the x value to see if one exists in the estimates array
          if (chartDataArrEstimates.map((o) => o.x).indexOf(actual.x) === -1) {
            // Doesn't exist, so need to create a fake value
            chartDataArrEstimates.push({
              x: actual.x,
              y: null,
            });
          }
        });
      }
      // Now do the same to match up the other array, so one doesn't have more than the other
      if (chartDataArrEstimates.length > chartDataArrActuals.length) {
        chartDataArrEstimates.forEach((estimate) => {
          // Check the x value to see if one exists in the actuals array
          if (chartDataArrActuals.map((o) => o.x).indexOf(estimate.x) === -1) {
            // Doesn't exist, so need to create a fake value
            chartDataArrActuals.push({
              x: estimate.x,
              y: null,
            });
          }
        });
      }
    } else if (this.state.segmentValue === 'hh') {
      console.log('- START HH WORK --');
      const meterpointId = this.state.selectedMeterpoint.sourceid;
      const hh_startDate = this.state.startDate.toISOString().slice(0, 10);


      //If the new end date is more than two days ago, limit it to be 2 days ago
      const newEndDate = this.state.endDate;
      const currentDate = new Date();
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      if(this.state.endDate > twoDaysAgo) {
        newEndDate.setDate(currentDate.getDate() - 2);
      }

      const hh_newEndDate= newEndDate.toISOString().slice(0, 10);
      const hh_url = `${seaglassURL}sgCore/elecmeterpoints/${meterpointId}/hhreads/${hh_startDate}/${hh_newEndDate}`;
      POSTSeaglass(hh_url)
        .then((hh_response) => {
          console.log(hh_response);
          if (handleResponseCode(hh_response)) {
            if (hh_response.response.data) {
              hh_response.response.data.forEach((hh_row) => {
                chartDatahhArr.push(hh_row);
              });
              this.setState({
                hhChartData: chartDatahhArr,
              });
            }
          } else if (hh_response && hh_response.message) {
            showAlert('Error', hh_response.message);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    }
    if (this.state.segmentValue === "month") {
      chartDataArrActuals.sort((a, b) => {
        const aDate = a.x.split("-");
        const bDate = b.x.split("-");
        return (
          new Date(aDate[1], aDate[0]) -
          new Date(bDate[1], bDate[0])
        );
      });

      chartDataArrEstimates.sort((a, b) => {
        const aDate = a.x.split("-");
        const bDate = b.x.split("-");
        return (
          new Date(aDate[1], aDate[0]) -
          new Date(bDate[1], bDate[0])
        );
      });
    } else if(this.state.segmentValue === "day") {
      chartDataArrActuals.sort((a, b) => {
        const aDate = a.x.split("-");
        const bDate = b.x.split("-");
        return (
          new Date(aDate[2], aDate[1], aDate[0]) -
          new Date(bDate[2], bDate[1], bDate[0])
        );
      });

      chartDataArrEstimates.sort((a, b) => {
        const aDate = a.x.split("-");
        const bDate = b.x.split("-");
        return (
          new Date(aDate[2], aDate[1], aDate[0]) -
          new Date(bDate[2], bDate[1], bDate[0])
        );
      });
    }
      this.setState({
        chartDataActuals: chartDataArrActuals,
        chartDataEstimates: chartDataArrEstimates,
        hhChartData: chartDatahhArr,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      });
  };

  // Set Sites Data
  setSitesData = async (response) => {
    response.forEach((element, index) => {
      console.log("res for site response ::: ", element);
      // const siteDataLocal = JSON.parse(element.sitedata);
      const siteDataAddress = JSON.parse(element.sitedata);
      console.log("res for sitedataddress:",siteDataAddress);
        // Handle Address if some field have Null
        const buildingNo = siteDataAddress.address.buildingnumber;
        const buildingName = siteDataAddress.address.buildingname;
        const localityName = siteDataAddress.address.locality;
        const thoroughFare = siteDataAddress.address.thoroughfare;
        const postTown = siteDataAddress.address.posttown;
        const postCode = siteDataAddress.address.postcode;
        const countyName = siteDataAddress.address.county;
        const addressText = [
          buildingNo,
          buildingName,
          localityName,
          thoroughFare,
          postTown,
          postCode,
          countyName,
        ]
          .filter(Boolean)
          .join(", ");
      let mpxnText = '';
      console.log("addresstext: ",addressText);
      // eslint-disable-next-line
      if(element.utility == 'Electricity') {
        mpxnText = 'MPAN';
      } else {
        mpxnText = 'MPRN';
      }
      const siteData = {
        meterid: element.productid,
        utility: element.utility,
        mpxnText,
        addressText
      };
      getElecOrGasMeterData(siteData).then((res) => {
        console.log('res for sitedetail ::: ', res);
        console.log(siteData);
        // eslint-disable-next-line react/no-access-state-in-setstate
        const sitesListArr = this.state.sitesList;
        sitesListArr[index].meterPointRef = res.meterPointRef;
        sitesListArr[index].estimatedAnnualUsage = res.estimatedAnnualUsage;
        sitesListArr[index].addressText = siteData.addressText;
        sitesListArr[index].mpxnText = siteData.mpxnText;
        this.setState({
          sitesList: sitesListArr,
        });
      });
    });
  };

  handleChangeStart = (startDate) => {
 /*    this.setState({
      startDate,
    }); */
    // eslint-disable-next-line
    this.state.startDate = startDate;
    setTimeout(() => {
      this.setChartData();
    }, 300);
  };

  handleChangeEnd = (endDate) => {
/*     this.setState({
      endDate,
    }); */
  // eslint-disable-next-line
    this.state.endDate = endDate;
    setTimeout(() => {
      this.setChartData();
    }, 300);
  };


  handleEstimatedDataClick = () => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open('https://www.valdaenergy.com/help-and-faqs/estimated-meter-reads-on-customer-portal','_blank','location=no');
    } else {
      window.open('https://www.valdaenergy.com/help-and-faqs/estimated-meter-reads-on-customer-portal', '_system');
    }
  };

  handleDownloadData = (segment) => {
    logEvent('download_usage_data');
    const accesstoken = localStorage.getItem(token);
    const meterpointid = this.state.selectedMeterpoint.id;
    const meterpointidhh = this.state.selectedMeterpoint.sourceid;
    const selectedSegment = this.state.segmentValue;
    let url = '';
    if (segment === undefined) {
      const startDate = this.state.startDate.toISOString().slice(0, 10);
      const endDate = this.state.endDate.toISOString().slice(0, 10);
      if(selectedSegment === 'hh') {
        url = `${valdaUrl}getHHUsageCSV/${meterpointidhh}/${startDate}/${endDate}?access_token=${accesstoken}`;
      } else {
        url = `${valdaUrl}getUsageCSV/${meterpointid}/${startDate}/${endDate}?access_token=${accesstoken}`;
      }
    } else {
      const startDate = this.state.startDate.toISOString().slice(0, 10);
      const endDate = this.state.endDate.toISOString().slice(0, 10);
      url = `${valdaUrl}getHHUsageCSV/${meterpointidhh}/${startDate}/${endDate}?access_token=${accesstoken}`;
    }

    if (window.cordova) {
      window.plugins.socialsharing.share('Here is your data:', 'Data', url);
      return;
    } else {
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  handleDataHelp = () => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        'https://www.valdaenergy.com/help-and-faqs/how-to-read-usage-data',
        '_blank',
        'location=no'
      );
    } else {
      window.open(
        'https://www.valdaenergy.com/help-and-faqs/how-to-read-usage-data',
        '_system'
      );
    }
  };

  render() {
    logScreenView("Usage");
    const sitesSelectList = this.state.sitesList.map((site) => (
      <option key={site.id} value={site.id}>
        {`${site.mpxnText}: ${site.meterPointRef} - ${site.utility} (${site.addressText})`}
      </option>
    ));
    const {
      startDate,
      endDate,
      today,
      segmentValue,
      earliestDate,
    } = this.state;

    let chartElement = '';
    const oneDay = 24 * 60 * 60 * 1000;
    const totalDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    let estimationAlert = '';
    let hhAlert = '';
    // Calculate if the chosen segment is actually valid for the amount of days
    if (segmentValue === 'hh' && totalDays > 14) {
      // Switch the segmentValue back to day and give an alert
      console.log('You cant view more than 14 days worth in hh view');
      hhAlert = (
        <Alert
          severity="info"
        >
          Please note that viewing over 14 days of half hourly data is not recommended
        </Alert>
      );
    } else {
      console.log('This view is valid');
    }
    if (this.state.chartDataEstimates.length > 0) {
      estimationAlert = (
        <Alert
          severity="info"
          action={(
            <Button
              color="inherit"
              size="small"
              onClick={this.handleEstimatedDataClick}
            >
              Find out why
            </Button>
          )}
        >
          Please note one or more of your reads are estimated
        </Alert>
      );
    }
    console.log(`StartDate: ${startDate}`);
    console.log(`EndDate: ${endDate}`);
    console.log(`Segment by: ${segmentValue}`);
    console.log(`Total days: ${totalDays}`);
    console.log('this.state.chartDataActuals: ', this.state.chartDataActuals);
    console.log(
      'this.state.chartDataEstimates: ',
      this.state.chartDataEstimates
    );
    console.log('this.state.hhChartData: ', this.state.hhChartData);

    if (this.state.segmentValue === 'month') {
      chartElement = (
        <>
          {estimationAlert}
          <LineChart
            chartDataActuals={this.state.chartDataActuals}
            chartDataEstimates={this.state.chartDataEstimates}
            strokeDashValue={
              this.state.selectedMeterpoint.meteringtypeid === 3 ? 0 : 9
            }
            xAxisText="Month"
          />
        </>
      );
    } else if (this.state.segmentValue === 'day') {
      chartElement = (
        <>
          {estimationAlert}
          <LineChart
            chartDataActuals={this.state.chartDataActuals}
            chartDataEstimates={this.state.chartDataEstimates}
            strokeDashValue={
              this.state.selectedMeterpoint.meteringtypeid === 3 ? 0 : 9
            }
            xAxisText="Day"
          />
        </>
      );
    } else if (this.state.segmentValue === 'hh') {
      chartElement = (
        <>
          {hhAlert}
          {estimationAlert}
          <HHBarChart
            chartData={this.state.hhChartData}
          />
        </>
      );
    }
    if (
      ((!this.state.chartDataActuals ||
        this.state.chartDataActuals.length <= 1) &&
      (!this.state.chartDataEstimates ||
        this.state.chartDataEstimates.length <= 1)) &&
        this.state.hhChartData.length < 1
    ) {
      chartElement = (
        <div className="bigText centerText paddingBottomOne marginTop">
          No data available
        </div>
      );
    }

    const { utility } = this.state.selectedMeterpoint;
    const utilityCss =
      this.state.selectedMeterpoint.utilityid === 1
        ? 'usageElectricityIcon'
        : 'usageGasIcon';

    const { meterPointRef } = this.state.selectedMeterpoint;

    const accountBalanceValue = localStorage.getItem(accountBalance);
    const debitCreditTextValue = localStorage.getItem(debitCreditText);
    const balanceColour =
      debitCreditTextValue.toLowerCase().indexOf('credit') !== -1
        ? '#009b4a'
        : '#c70100';
    return (
      <div>
        <div className="dontShowOnDesktop">
          <CustomTitlebar
            menuClick={this.props.menuClick}
            showMenuBtn
            titleText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div>
                <span className="boldText">Usage</span>
              </div>
            }
          />
        </div>
        <div className="scrollingContainer usageScroller marginTop">
          <Box boxShadow={2} className="nintyPercentWidth curvyCorners whiteBg">
            <TextField
              select
              label=""
              size="small"
              className="meterPoint"
              value={this.state.selectedMeterpoint.id}
              onChange={this.handleSiteChange}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              style={{
                marginTop: '1em',
                marginLeft: '5px',
                marginBottom: '1em',
                minWidth: '15em',
                width: '97%',
                overflow: 'hidden'
              }}
            >
              {sitesSelectList}
            </TextField>
            <div
              className="boldText"
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                marginLeft: '1em',
              }}
            >
              DATE RANGE:
            </div>
            <div className="date-range" style={{ padding: '10px' }}>
              <div
                className="date-range"
                style={{ width: '49%', display: 'inline-block' }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={this.handleChangeStart}
                  selectsStart
                  startDate={startDate}
                  minDate={earliestDate}
                  endDate={endDate}
                  locale="en-GB"
                  dateFormat="P"
                  style={{ width: '50%' }}
                />
                <KeyboardArrowDownIcon />
              </div>
              <div
                className="date-range"
                style={{
                  width: '49%',
                  display: 'inline-block',
                  marginLeft: '1%',
                }}
              >
                <DatePicker
                  selected={endDate}
                  onChange={this.handleChangeEnd}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={today}
                  locale="en-GB"
                  dateFormat="P"
                  style={{ width: '50%' }}
                />
                <KeyboardArrowDownIcon />
              </div>
            </div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <div
                className="boldText"
                style={{
                  paddingTop: '26px',
                  paddingLeft: '5px',
                  display: 'inline-block',
                  width: '49%',
                }}
              >
                SEGMENT BY:
              </div>
              <TextField
                select
                label=""
                defaultValue="day"
                size="small"
                value={this.state.segmentValue}
                onChange={this.handleSegmentChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                style={{
                  marginTop: '1em',
                  minWidth: '0',
                  display: 'inline-block',
                  marginLeft: '1%',
                  width: '49%',
                }}
              >
                <option key="hh" value="hh" disabled={this.state.hhDisabledStatus}>
                  Half Hour
                </option>
                <option key="day" value="day">
                  Day
                </option>
                <option key="month" value="month">
                  Month
                </option>
              </TextField>
            </div>
            <div id="lineChart" style={{ marginTop: '20px' }}>
              {chartElement}
            </div>
          </Box>
          <div
            className="data-download-container"
            style={{
              padding: '30px',
            }}
          >
            <CustomButton
              btnName="DOWNLOAD DATA"
              btnClick={this.handleDownloadData}
              startBtnIcon={<GetAppIcon />}
            />
            <CustomLink
              linkText="Need help interpreting your data?"
              linkClick={this.handleDataHelp}
            />
          </div>
          <Box
            boxShadow={2}
            className="nintyPercentWidth curvyCorners whiteBg marginTop accountOverview"
            onClick={this.handleListItemClick}
          >
            <div
              className="boldText"
              style={{ paddingTop: '10px', marginLeft: '1em' }}
            >
              Account Overview
            </div>
            <div
              className="smallMarginTop"
              style={{ marginLeft: '10px', marginRight: '10px' }}
            >
              <span className={`usageIcon ${utilityCss}`} />
              <span
                style={{
                  paddingLeft: '1em',
                  float: 'left',
                }}
                className="usageUtilityContainer"
              >
                <div className="accountOverviewUtilityText">{utility}</div>
                <div className="meterNumber">{meterPointRef}</div>
              </span>
              <span
                style={{
                  paddingLeft: '1em',
                  float: 'right',
                  textAlign: 'right',
                }}
              >
                <div
                  className="accountOverviewUtilityText"
                  style={{ color: balanceColour }}
                >
                  {`£${accountBalanceValue}`}
                </div>
                <div className="meterNumber" style={{ color: balanceColour }}>
                  {debitCreditTextValue}
                </div>
              </span>
            </div>
          </Box>
        </div>
      </div>
    );
  }
}

export default Usage;

Usage.propTypes = {
  menuClick: PropTypes.func,
};

Usage.defaultProps = {
  menuClick: () => false,
};
