import {
  token,
  valdaUrl,
  refreshToken,
  deviceId,
  seaglassBasicAuth,
  isRefreshTokenApiCall,
} from '../../constants';
// eslint-disable-next-line import/no-cycle
import { storeTokenData, showSpinner, removeTokenData } from '../../utility';

// Api call for Miramar's server
const POSTValda = (_apiname, _apiParam) => new Promise((resolve, reject) => {
  showSpinner(true);
  console.log(`Post Url=${_apiname}`);
  console.log(_apiParam);
  const username = 'valdaapp';
  const password = '9BC3T0B$oV2GAtcY';
  const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;
  const customHeader = {
    // Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-app-version-number': 1.0,
    Authorization: basicAuth,
  };

  fetch(_apiname, {
    method: 'POST',
    headers: customHeader,
    body: JSON.stringify(_apiParam),
    // eslint-disable-next-line consistent-return
  }).then((response) => {
    showSpinner(false);
    console.log('response :', response);
    if (response.status >= 200 && response.status < 300) {
      if (response.headers.get('Content-Type') != null) {
        return resolve(response.json());
      }
    } else {
      if (
        response.headers.get('Content-Type') != null
          && response.headers.get('Content-Type')
            === 'application/json;charset=UTF-8'
      ) {
        return reject(response.json());
      }
      if (response.headers.get('Content-Type') != null) {
        return reject(response);
      }
      return reject(response.blob());
    }
  });
});

// Api call for Seaglass api through Miramar's server
const POSTSeaglass = (_apiname, _apiParam) => new Promise((resolve, reject) => {
  showSpinner(true);
  console.log(`Post Url=${_apiname}`);
  const customHeader = {
    'Content-Type': 'application/json',
    'x-app-version-number': 1.0,
    Authorization: seaglassBasicAuth,
  };
  let apiParam = {};
  if (_apiParam) {
    apiParam = _apiParam;
  }
  const accessToken = localStorage.getItem(token);
  apiParam.access_token = `${accessToken}`;

  fetch(_apiname, {
    method: 'POST',
    headers: customHeader,
    body: JSON.stringify(apiParam),
  // eslint-disable-next-line consistent-return
  }).then((response) => {
    showSpinner(false);
    if (response.status >= 200 && response.status < 300) {
      if (response.headers.get('Content-Type') != null) {
        return resolve(response.json());
      }
    } else {
      if (
        response.headers.get('Content-Type') != null
          && response.headers.get('Content-Type')
            === 'application/json;charset=UTF-8'
      ) {
        return reject(response.json());
      }
      if (response.headers.get('Content-Type') != null) {
        return reject(response);
      }
      return reject(response.blob());
    }
  });
});

// Refresh Token Api called when Response status code = 400
const RefreshTokenAPI = () => {
  const url = `${valdaUrl}refreshToken`;
  console.log(localStorage.getItem(deviceId));
  const apiData = {
    refresh_token: localStorage.getItem(refreshToken),
    device_id: localStorage.getItem(deviceId),
  };

  POSTValda(url, apiData)
    .then((response) => {
      localStorage.setItem(isRefreshTokenApiCall, false);
      console.log(response);
      if (response.response_code === 200 && response.result) {
        if (response.data) {
          storeTokenData(response.data);
        }
      } else {
        removeTokenData();
        // window.open('/', '_self');
        window.location.reload(true);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export { POSTValda, POSTSeaglass, RefreshTokenAPI };
