import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const HelpLink = (props) => {
  const handleLinkClick = () => {
    props.linkClick();
  };

  return (
    <Button className="helpLink" onClick={handleLinkClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path id="Icon_metro-question" data-name="Icon metro-question" d="M9.571,12.928h2v2h-2Zm4-7a1,1,0,0,1,1,1v3l-3,2h-2v-1l3-2v-1h-5v-2h6Zm-3-2.5a6.5,6.5,0,1,0,4.6,1.9,6.457,6.457,0,0,0-4.6-1.9Zm0-1.5a8,8,0,1,1-8,8,8,8,0,0,1,8-8Z" transform="translate(-2.571 -1.928)" fill="#0a7c41"/>
      </svg>&nbsp;&nbsp;
      {props.linkText}
    </Button>
  );
};

export default HelpLink;
HelpLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  linkClick: PropTypes.func.isRequired,
};
