import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import zxcvbn from 'zxcvbn';

import './AccountInformation.css';
import CustomInputField from '../../hoc/CustomInputField';
import CustomButton from '../../hoc/CustomButton';
import { token, valdaUrl } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import { handleResponseCode, showAlert, handleException, logScreenView, logEvent } from '../../utility';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      newPassword: '',
      confirmPassword: '',
      errorpassword: false,
      passwordErrorMessage: '',
      errorNewpassword: false,
      newPasswordErrorMessage: '',
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: '',
    };
  }

  // Validation For Update Password Page
  validateUpdatePassword = () => {
    if (this.state.password === '') {
      this.setState({
        errorpassword: true,
        passwordErrorMessage: "Password can't be blank.",
      });
      return false;
    }

    if (this.state.newPassword === '') {
      this.setState({
        errorNewpassword: true,
        newPasswordErrorMessage: "New Password can't be blank.",
      });
      return false;
    }

    const passwordScore = zxcvbn(this.state.newPassword);
    console.log('passwordScore :', passwordScore.score);
    if (passwordScore.score < 2) {
      // password score must be >= 2
      this.setState({
        errorNewpassword: true,
        newPasswordErrorMessage:
          'New Password is weak so please reenter strong password.',
      });
      return false;
    }

    if (this.state.confirmPassword === '') {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage: "Confirm Password can't be blank.",
      });
      return false;
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage:
          'Confirm Password is not same as New Password',
      });
      return false;
    }
    return true;
  };

  // Api call for Change Password
  handleUpdatePassword = () => {
    logEvent('update_password');
    if (!this.validateUpdatePassword()) {
      return;
    }

    const url = `${valdaUrl}changePassword`;
    const apiData = {
      access_token: localStorage.getItem(token),
      current_password: this.state.password,
      new_password: this.state.newPassword,
      repeat_new_password: this.state.confirmPassword,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log('Password changed successfully');
          this.setState({
            password: '',
            newPassword: '',
            confirmPassword: '',
          });
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorpassword: false,
      passwordErrorMessage: '',
    });
  };

  // Input field handle - New Password
  handleNewPasswordChange = (newValue) => {
    this.setState({
      newPassword: newValue,
      errorNewpassword: false,
      newPasswordErrorMessage: '',
    });
  };

  // Input field handle - Confirm Password
  handleConfirmPasswordChange = (newValue) => {
    this.setState({
      confirmPassword: newValue,
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: '',
    });
  };

  render() {
    logScreenView("Update Password");
    return (
      <div className="marginTop">
        <Box boxShadow={2} className="curvyCorners whiteBg">
          <div className="title">Update Password</div>
          <div className="nintyPercentWidth paddingBottomOne">
            <CustomInputField
              fieldName="Current Password"
              fieldType="password"
              fieldValue={this.state.password}
              isError={this.state.errorpassword}
              errorMessage={this.state.passwordErrorMessage}
              textChange={this.handlePasswordChange}
            />
            <CustomInputField
              fieldName="New Password"
              fieldType="password"
              fieldValue={this.state.newPassword}
              isError={this.state.errorNewpassword}
              errorMessage={this.state.newPasswordErrorMessage}
              textChange={this.handleNewPasswordChange}
            />
            <CustomInputField
              fieldName="Confirm New Password"
              fieldType="password"
              fieldValue={this.state.confirmPassword}
              isError={this.state.errorConfirmPassword}
              errorMessage={this.state.confirmPasswordErrorMessage}
              textChange={this.handleConfirmPasswordChange}
            />
          </div>
        </Box>
        <CustomButton
          btnName="Update Password"
          btnClick={this.handleUpdatePassword}
          showFullWidth
          endBtnIcon={<ArrowForwardIcon />}
        />
      </div>
    );
  }
}

export default UpdatePassword;
