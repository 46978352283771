import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import CustomInputField from '../../hoc/CustomInputField';
import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import { valdaUrl, deviceId } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import {
  storeTokenData,
  handleResponseCode,
  showAlert,
  handleException,
  logScreenView
} from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class VerifyCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      errorOtp: false,
      otpErrorMessage: '',
      navigateToHome: false,
      navigateToCreatePin: false,
      navigateToContactUs: false,
    };
  }

  // Handle Continue button click to verify code
  handleContinue = () => {
    // Validation for otp is blank or not
    if (this.state.otp === '') {
      this.setState({
        errorOtp: true,
        otpErrorMessage:
          "Please enter an OTP. If you don't get it then request again it",
      });
      return;
    }
    // Api call for Verify code
    const url = `${valdaUrl}validateOTP`;
    console.log(localStorage.getItem(deviceId));
    const apiData = {
      otp: this.state.otp,
      device_id: localStorage.getItem(deviceId),
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.data) {
            storeTokenData(response.data);

            if (window.cordova) {
              // Go to get pin code to do quick login, if app is running on mobile.
              this.setState({
                navigateToCreatePin: true,
              });
            } else {
              // Go to home page if app is running in browser
              this.setState({
                navigateToHome: true,
              });
            }
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Input field handle - OTP
  handleOTPChange = (newValue) => {
    this.setState({
      otp: newValue,
      errorOtp: false,
      otpErrorMessage: '',
    });
  };

  // Handle it when we don't get code
  handleDontHaveCode = () => {
    this.setState({ navigateToContactUs: true });
  };

  render() {
    logScreenView("Verify OTP");
    if (this.state.navigateToHome) {
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: { validated: true },
          }}
        />
      );
    }

    if (this.state.navigateToContactUs) {
      return <Redirect to="/contactus" />;
    }

    if (this.state.navigateToCreatePin) {
      return <Redirect to="/createpin" />;
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="VERIFY CODE" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <div className="centerText marginTop">
            Please enter your code you have received via email or SMS to access the Valda app.
          </div>
          <CustomInputField
            fieldName="Enter your code"
            fieldType="text"
            fieldValue={this.state.otp}
            isError={this.state.errorOtp}
            errorMessage={this.state.otpErrorMessage}
            textChange={this.handleOTPChange}
          />
          <CustomButton
            btnName="CONTINUE"
            btnClick={this.handleContinue}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
          <CustomLink
            linkText="DON'T HAVE CODE? CONTACT US"
            linkClick={this.handleDontHaveCode}
          />
        </Grid>
      </div>
    );
  }
}

export default VerifyCode;
