import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import InvoiceListItem from '../InvoiceListItem/InvoiceListItem';
import {
  seaglassURL,
  accountBalance,
  debitCreditText,
  accountID
} from '../../constants';
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import {
  handleResponseCode,
  showAlert,
  handleException,
  logScreenView
} from '../../utility';
import ImportantInformation from '../../hoc/ImportantInformation';

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImportantInfo: false,
      invoiceList: [],
    };
  }

  componentDidMount() {
    // Api call for GetInvoiceDetailByAccountId

    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(accountID)}/invoices`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data) {
            this.setState({
              showImportantInfo: true,
              invoiceList: response.response.data,
            });
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }


  handleMakePayment = () => {
    this.props.makePaymentClick(true);
  };

  handleListItemClick = (invoiceObj) => {
    this.props.goToInvoiceDetails(invoiceObj);
  };

  render() {
    logScreenView("Invoice list");
    let listItems;
    if (this.state.invoiceList) {
      // eslint-disable-next-line arrow-body-style
      listItems = this.state.invoiceList.map((item) => {
        return (
          <InvoiceListItem
            record={item}
            key={item.id}
            goToInvoiceDetails={this.handleListItemClick}
          />
        );
      });
    }

    const accountBalanceValue = localStorage.getItem(accountBalance);
    const debitCreditTextValue = localStorage.getItem(debitCreditText);

    let importantInfo = '';
    if (this.state.showImportantInfo) {
      if (this.state.invoiceList.length === 0) {
        const infoElement = (
          <div>
            Your first invoice will be issued within 30 days after the date your
            energy goes live. If you have any questions about payments or wish
            to change your payment method, please contact us using the channels
            detailed in the Contact Us section.
          </div>
        );
        importantInfo = <ImportantInformation displayText={infoElement} />;
      }
      if (this.state.invoiceList.length === 1) {
        const infoElement = (
          <div>
            If you have any questions about payments or wish to change your
            payment method, please contact us using the channels detailed in the
            Contact Us section.
          </div>
        );
        importantInfo = <ImportantInformation displayText={infoElement} />;
      }
    }

    return (
      <div className="desktopFullScreenContainer">
        <CustomTitlebar
          menuClick={this.props.menuClick}
          showMenuBtn
          titleText={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <div>
                <span className="bigText boldText">{`£${accountBalanceValue}  `}</span>
                <span>{debitCreditTextValue}</span>
              </div>
              <div>Account Balance</div>
            </div>
          }
        />
        <div className="scrollingContainer invoiceScroller">
          <div>
            <h3 className="centerText">INVOICES</h3>
            <TableContainer className="invoice-table nintyPercentWidth curvyCorners whiteBg marginTop">
              <Table aria-label="simple table invoice-table" className="">
                <TableHead>
                  <TableRow>
                    <TableCell>DATE</TableCell>
                    <TableCell>DUE DATE</TableCell>
                    <TableCell>INVOICE REF.</TableCell>
                    <TableCell>CHARGES</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{listItems}</TableBody>
              </Table>
            </TableContainer>
            {importantInfo}
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceList;

InvoiceList.propTypes = {
  menuClick: PropTypes.func,
  makePaymentClick: PropTypes.func.isRequired,
  goToInvoiceDetails: PropTypes.func.isRequired,
};

InvoiceList.defaultProps = {
  menuClick: () => false,
};
