import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './InvoiceListItem.css';
import { dateToDateFormat, currencyFormat } from '../../utility';

class InvoiceListItem extends React.PureComponent {
  handleListItemClick = () => {
    this.props.goToInvoiceDetails(this.props.record);
  };

  render() {
    const date = dateToDateFormat(this.props.record.invoicedate);
    const dueDate = dateToDateFormat(this.props.record.duedate);
    const paymentAmountText = `£${currencyFormat(
      this.props.record.totalvalue
    )}`;
    return (
      <TableRow key={this.props.record.id} onClick={this.handleListItemClick}>
        <TableCell>{date}</TableCell>
        <TableCell>{dueDate}</TableCell>
        <TableCell>{this.props.record.id}</TableCell>
        <TableCell>{paymentAmountText}</TableCell>
        <TableCell>View</TableCell>
      </TableRow>
    );
  }
}

InvoiceListItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  goToInvoiceDetails: PropTypes.func.isRequired,
};
export default InvoiceListItem;
