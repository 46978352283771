import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './PaymentListItem.css';
import { dateToDateFormat, currencyFormat } from '../../utility';

class PaymentListItem extends React.PureComponent {
  handleListItemClick = () => {
    this.props.goToInvoiceDetails(this.props.record);
  };

  render() {
    const date = dateToDateFormat(this.props.record.cleareddate);
    const paymentAmountText = `£${currencyFormat(this.props.record.value)}`;
    if (this.props.record.paymentstatus === 'Cleared') {
      return (
        <TableRow key={this.props.record.id}>
          <TableCell>{date}</TableCell>
          <TableCell>{this.props.record.paymenttype}</TableCell>
          <TableCell>{paymentAmountText}</TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
      
  }
}

PaymentListItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired
};
export default PaymentListItem;
