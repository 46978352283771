import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const ImportantInformation = (props) => (
  <div>
    <Box
      boxShadow={2}
      className="nintyPercentWidth curvyCorners whiteBg marginTop"
    >
      <div className="grayThickBar curvyTopCorners" />
      <div style={{ padding: '1em' }}>
        <div className="boldText" style={{ fontSize: '1.2em' }}>
          Important Information
        </div>
        <div className="smallMarginTop">{props.displayText}</div>
      </div>
    </Box>
  </div>
);

export default ImportantInformation;

ImportantInformation.propTypes = {
  displayText: PropTypes.element.isRequired,
};
