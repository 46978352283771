import React, { Component } from 'react';
import PdfJsViewer from '../PDFJS/PdfJSViewer';
import CustomTitlebar from '../../hoc/CustomTitlebar';

class ViewInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleBackClick = () => {
    this.props.goToInvoiceList(false);
  };

  render() {
    console.log("props are ",this.props);
    return (
      <div className="desktopHalfScreenContainer">
        <CustomTitlebar
          backClick={this.handleBackClick}
          showBackBtn
          titleText={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <div>{`Viewing Invoice ${this.props.invoiceObj[0]}`}</div>
            </div>
          }
        />
        <PdfJsViewer height="calc(100vh - 100px - 55px)" width="100vw" url={this.props.invoiceObj[1]}/>
      </div>
    );
  }
}

export default ViewInvoice;

ViewInvoice.defaultProps = {
  goToInvoiceList: () => false,
};
