import React, { Component } from 'react';

import './HomeNavigation.css';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import PropTypes from 'prop-types';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import PublishIcon from "@material-ui/icons/PublishOutlined";
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';

class HomeNavigation extends Component {
  // Change Tab according to User Click
  changeActiveTab = (event, newValue) => {
    this.props.changeBottomTabActiveTab(newValue);
  };

  render() {
    return (
      <BottomNavigation
        value={this.props.currentTab}
        onChange={this.changeActiveTab}
        showLabels
        className="tabBarContainer"
      >
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<HomeOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Usage"
          value="usage"
          icon={<DataUsageOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Payments"
          value="payment"
          icon={<PaymentOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Meter Read"
          value="meterread"
          icon={<PublishIcon />}
        />
      </BottomNavigation>
    );
  }
}

export default HomeNavigation;

HomeNavigation.propTypes = {
  currentTab: PropTypes.string.isRequired,
  changeBottomTabActiveTab: PropTypes.func.isRequired,
};
