import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from "@material-ui/icons/Visibility";
import Box from '@material-ui/core/Box';

import { accountBalance, debitCreditText, seaglassURL } from '../../constants';
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import CustomButton from '../../hoc/CustomButton';
import {
  dateToDateFormat,
  handleResponseCode,
  showAlert,
  handleException,
  currencyFormat,
  logScreenView,
  logEvent
} from '../../utility';

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleBackClick = () => {
    this.props.goToInvoiceList(false);
  };

  handleView = () => {
    // Api call for GetInvoiceDetailByAccountId
    // "invoiceid" : 715
    logEvent('download_invoice');
    const url = `${seaglassURL}sgComms/functions/getdocument`;
    POSTSeaglass(url, { invoiceid: this.props.invoiceObj.id })
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.returndata &&
            response.response.data.returndata.docurl
          ) {
            console.log("response", response);
            if (window.cordova) {
              window.cordova.InAppBrowser.open(
                "https://account.valdaenergy.com/api/1.0/proxyDocument?url=" +
                  encodeURIComponent(response.response.data.returndata.docurl) +
                  "&invoice_id=" +
                  this.props.invoiceObj.id,
                "_system",
                "location=no"
              );
              return;
            } else {
              var element = document.createElement("a");
              element.setAttribute(
                "href",
                response.response.data.returndata.docurl
              );
              element.style.display = "none";
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            }
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleDownload = () => {
    // Api call for GetInvoiceDetailByAccountId
    // "invoiceid" : 715
    const url = `${seaglassURL}sgComms/functions/getdocument`;
    POSTSeaglass(url, { invoiceid: this.props.invoiceObj.id })
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.returndata &&
            response.response.data.returndata.docurl
          ) {
            console.log("response", response);
            if (window.cordova) {
              window.plugins.socialsharing.share(
                `Here is your invoice: ${this.props.invoiceObj.id}`,
                `Invoice${this.props.invoiceObj.id}`,
                response.response.data.returndata.docurl
              );
              window.open(response.response.data.returndata.docurl, "_system");
              return;
            } else {
              var element = document.createElement("a");
              element.setAttribute(
                "href",
                response.response.data.returndata.docurl
              );
              element.style.display = "none";
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            }
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };


  render() {
    logScreenView("Invoice details");
    const accountBalanceValue = localStorage.getItem(accountBalance);
    const debitCreditTextValue = localStorage.getItem(debitCreditText);
    // eslint-disable-next-line prefer-destructuring
    const invoiceObj = this.props.invoiceObj;

    let invoiceNumber = "";
    let invoiceDate = "";
    let netValue = "";
    let totalValue = "";
    let vatValue = "";
    let cclValue = "";
    let vatPercentage = "5%";

    if (invoiceObj) {
      if (invoiceObj.id) {
        invoiceNumber = invoiceObj.id;
      }

      if (invoiceObj.invoicedate) {
        invoiceDate = invoiceObj.invoicedate;
      }

      if (invoiceObj.netvalue) {
        netValue = currencyFormat(invoiceObj.netvalue);
      }

      if (invoiceObj.totalvalue) {
        totalValue = currencyFormat(invoiceObj.totalvalue);
      }

      if (invoiceObj.vatvalue) {
        vatValue = currencyFormat(invoiceObj.vatvalue);
      }

      if (invoiceObj.cclvalue) {
        cclValue = invoiceObj.cclvalue;
      }

      if (cclValue) {
        vatPercentage = "20%";
      }
    }

    invoiceDate = dateToDateFormat(invoiceDate);

    return (
      <div className="desktopHalfScreenContainer">
        <CustomTitlebar
          backClick={this.handleBackClick}
          showBackBtn
          titleText={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <div>{`Invoice - ${invoiceNumber}`}</div>
              <div>
                <span className="bigText boldText">{`£${accountBalanceValue}  `}</span>
                <span>{debitCreditTextValue}</span>
              </div>
              <div>Account Balance</div>
            </div>
          }
        />
        <h3 className="centerText">INVOICE SUMMARY</h3>
        <div className="scrollingContainer invoiceScroller">
          <div className="nintyPercentWidth">
            <Box
              boxShadow={2}
              className="nintyPercentWidth curvyCorners whiteBg marginTop"
            >
              <div className="invoiceTitle">{`${invoiceDate} - ${invoiceNumber}`}</div>
              <div>
                <div className="paymentDetails">
                  <span className="paymentMode">Energy charges</span>
                  <span className="paymentAmount">{`£${netValue}`}</span>
                </div>
                <div className="paymentDetails">
                  <span className="paymentMode">{`VAT at ${vatPercentage}`}</span>
                  <span className="paymentAmount">{`£${vatValue}`}</span>
                </div>
                <div
                  className="paymentDetails"
                  style={{ backgroundColor: "#000000", color: "#ffffff" }}
                >
                  <span className="paymentMode">Total value</span>
                  <span className="paymentAmount">{`£${totalValue}`}</span>
                </div>
              </div>
            </Box>
            <CustomButton
              btnName="DOWNLOAD INVOICE"
              btnClick={this.handleView}
              showFullWidth
              startBtnIcon={<VisibilityIcon />}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceDetails;

InvoiceDetails.propTypes = {
  goToInvoiceList: PropTypes.func,
  viewPdf: PropTypes.func,
  invoiceObj: PropTypes.objectOf([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

InvoiceDetails.defaultProps = {
  viewPdf: () => false,
  goToInvoiceList: () => false,
};
