import React, { Component } from "react";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MobileSimpleTitlebar from "../../../hoc/MobileSimpleTitlebar";
import CustomButton from "../../../hoc/CustomButton";
import CustomLink from "../../../hoc/CustomLink";
import ImportantInformation from "../../../hoc/ImportantInformation";
import { logScreenView } from '../../../utility';
import { POSTSeaglass } from "../../../Global/Apis/ServerApi";
import { accountID, seaglassURL } from "../../../constants";
import { showAlert,handleResponseCode, handleException, logEvent } from '../../../utility';

class DirectDebit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleMakePayment = () => {
    this.props.showMakePayment();
  };

  handleContactUs = () => {
    this.props.showContactUs();
  };

  handleDDScreen = () => {
    logEvent('setup_direct_debit');
  // Check to see if the user already has a DD setup
  const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(accountID)}`;
  POSTSeaglass(url)
    .then((response) => {
      if (handleResponseCode(response)) {
        console.log(response.response.data);
        if(response.response.data.directdebit) {
          showAlert('Error', 'You already have a Direct Debit set up for this account. To amend your Direct Debit please contact Valda.');
        } else {
          this.props.showDDScreen();
        }
      } else if (response && response.message) {
        showAlert("Error", response.message);
      }
    })
    .catch((error) => {
      handleException(error);
    });
    
  };

  render() {
    logScreenView("Direct debit information");
    const infoElement = (
      <div>
        By moving to Direct Debit your payment will be taken 10 days after your
        invoice has been generated. Direct Debit payments are made
        automatically, so bills are never forgotten – saving you time. Plus it
        comes with the Direct Debit guarantee, giving you peace of mind.
      </div>
    );
    return (
      <div>
        <MobileSimpleTitlebar
          titleText="DIRECT DEBIT PAYMENTS"
          showMenuBtn
          menuClick={this.props.menuClick}
        />
        <div className="scrollingContainer makePaymentScroller">
          <div className="desktopHalfScreenContainer nintyPercentWidth">
            <h3 className="centerText">SETTING UP DIRECT DEBIT</h3>
            <ImportantInformation displayText={infoElement} />
            <CustomButton
              btnName="SET UP DIRECT DEBIT PAYMENTS"
              btnClick={this.handleDDScreen}
              endBtnIcon={<ArrowForwardIcon />}
              showFullWidth
            />
            <CustomLink
              linkText="Contact us for advice"
              linkClick={this.handleContactUs}
            />
            <CustomLink
              linkText="Back to card payments page"
              linkClick={this.handleMakePayment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DirectDebit;

DirectDebit.propTypes = {
  showMakePayment: PropTypes.func.isRequired,
  showContactUs: PropTypes.func.isRequired,
  showDDScreen: PropTypes.func.isRequired,
  menuClick: PropTypes.func,
};

DirectDebit.defaultProps = {
  menuClick: () => false,
};
