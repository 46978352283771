import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import SitesPricingListItem from "./SitesPricingListItem";

import './SitesListItem.css';

class SitesListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pricesList: this.props.record.prices,
    };
  }

  render() {
    console.log("this.props.record: ", this.props.record);
    const siteIdText = `Site ID ${this.props.record.siteid}`;
    const utilityText = this.props.record.utility;
    const metertype = this.props.record.meteringtypeid;
    let metertypeText = "";
    switch (metertype) {
      case 1:
      case 6:
        metertypeText = "Manual Meter";
        break;
      case 2:
      case 3:
      case 5:
        metertypeText = "Smart Meter";
        break;
      case 4:
        metertypeText = "Half-Hourly Meter";
        break;
      default:
        break;
    }
    let addressText = "";
    const sitedata = JSON.parse(this.props.record.sitedata);
    if (sitedata) {
      console.log("sitedata: ", sitedata);
      const siteDataAddress = sitedata.address;

      // Handle Address if some field have Null
      const buildingNo = siteDataAddress.buildingnumber;
      const buildingName = siteDataAddress.buildingname;
      const localityName = siteDataAddress.locality;
      const thoroughFare = siteDataAddress.thoroughfare;
      const postTown = siteDataAddress.posttown;
      const postCode = siteDataAddress.postcode;
      const countyName = siteDataAddress.county;

      addressText = [
        buildingNo,
        buildingName,
        localityName,
        thoroughFare,
        postTown,
        postCode,
        countyName,
      ]
        .filter(Boolean)
        .join(", ");
    }
    const utilityCss = `utilityIcon ${
      this.props.record.utilityid === 1
        ? "utilityElectricityIcon"
        : "utilityGasIcon"
    }`;
    const mpanText = this.props.record.mpan;
    const eacText = this.props.record.eac;
    const contractStart = this.props.record.contract_start;
    const contractLength = this.props.record.contract_length;
    const pricingItems = this.state.pricesList.map((item) => {
      return <SitesPricingListItem record={item} key={item.id} />;
    });
    return (
      <Box
        boxShadow={2}
        className="nintyPercentWidth curvyCorners whiteBg marginTop"
        onClick={this.handleListItemClick}
      >
        <div className="sitesTitle">
          <span className="paymentMode">{siteIdText}</span>
          <span className="paymentAmount">{utilityText}</span>
        </div>
        <div className="sitesDetails lineHeightSmall">
          <div>
            <div className="boldText smallMarginTop">Address</div>
            <div>{addressText}</div>
          </div>
          <div>
            <div className="boldText smallMarginTop">Meter Point Number</div>
            <div>{mpanText}</div>
          </div>
          <div>
            <div className="boldText smallMarginTop">Meter Type</div>
            <div>{metertypeText}</div>
          </div>
          {pricingItems}
          <div>
            <div className="boldText smallMarginTop">Contract Start Date</div>
            <div>{contractStart}</div>
          </div>
          <div>
            <div className="boldText smallMarginTop">Contract Length</div>
            <div>{contractLength}</div>
          </div>
          <div className={utilityCss}>
            <div className="boldText smallMarginTop">
              Est. annual usage(kWh)
            </div>
            <div>{eacText}</div>
          </div>
        </div>
      </Box>
    );
  }
}

SitesListItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SitesListItem;
