import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const CustomLink = (props) => {
  const handleLinkClick = () => {
    props.linkClick();
  };

  return (
    <Button className="linkText" onClick={handleLinkClick} style={{ fontSize: props.fontSize }}>
      {props.linkText}
    </Button>
  );
};

export default CustomLink;
CustomLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  linkClick: PropTypes.func.isRequired
};

CustomLink.defaults = {
  fontSize: '0.875rem'
};
