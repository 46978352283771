import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';

const BackButton = (props) => {
  const handleButtonClick = () => {
    props.btnClick();
  };

  return (
    <IconButton aria-label="back" onClick={handleButtonClick}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
BackButton.propTypes = {
  btnClick: PropTypes.func.isRequired,
};
