import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import SubTitle from '../../hoc/SubTitle';

// Success Callback page present when payment session is success
class SuccessCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToHome: false,
    };
  }

  // Handle Return which redirect to Home Page
  handleReturnHome = () => {
    this.setState({
      navigateToHome: true,
    });
  };

  render() {
    if (this.state.navigateToHome) {
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: { validated: true },
          }}
        />
      );
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="MAKE A PAYMENT" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <SubTitle titleText="Payment Accepted" />
          <div className="centerText marginTop">
            Thank you for your payment - It has been accepted.
          </div>
          <div className="centerText">
            Your account may take a few minutes to reflect this.
          </div>

          <CustomButton
            btnName="RETURN HOME"
            btnClick={this.handleReturnHome}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </Grid>
      </div>
    );
  }
}

export default SuccessCallback;
