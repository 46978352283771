import React, { Component } from 'react';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import { paymentsAndInvoices } from '../../FAQ/FaqText';
import SubTitle from '../../../hoc/SubTitle';
import MobileSimpleTitlebar from '../../../hoc/MobileSimpleTitlebar';
import SimpleAccordion from '../../../hoc/SimpleAccordion';
import { logScreenView } from "../../../utility";

class AlternativePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    logScreenView("Alternate payment");
    const text = paymentsAndInvoices.map((faq, index) => (
      <SimpleAccordion
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        blockTitle={faq.question}
        displayText={faq.answer}
      />
    ));
    return (
      <div>
        <MobileSimpleTitlebar
          titleText="Alternative Methods of Payments"
          showMenuBtn
          menuClick={this.props.menuClick}
        />
        <div className="dontShowOnMobile">
          <SubTitle titleText="Alternative Methods of Payments" />
        </div>
        <div className=" scrollingContainer faqScroller">
          <div className="nintyPercentWidth">
            <List component="nav" aria-label="main mailbox folders">
              {text}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default AlternativePayment;

AlternativePayment.propTypes = {
  menuClick: PropTypes.func,
};

AlternativePayment.defaultProps = {
  menuClick: () => false,
};
