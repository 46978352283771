import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const customButton = (props) => {
  const handleButtonClick = () => {
    props.btnClick();
  };

  return (
    <Button
      variant="contained"
      className="greenBtn"
      onClick={handleButtonClick}
      fullWidth={props.showFullWidth}
      endIcon={props.endBtnIcon}
      startIcon={props.startBtnIcon}
    >
      {props.btnName}
    </Button>
  );
};

export default customButton;
customButton.propTypes = {
  btnName: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
  showFullWidth: PropTypes.bool,
  endBtnIcon: PropTypes.element,
  startBtnIcon: PropTypes.element,
};

customButton.defaultProps = {
  showFullWidth: false,
  endBtnIcon: null,
  startBtnIcon: null
};
