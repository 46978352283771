import React from 'react'

const PdfJSViewer = ({ height, width, url }) => {
    // const path = 'https://lamp.miramar-group.co.uk/_valda-temp/pdfjs/web/viewer.html';
    // let pdfPath = url
    //   ? path + url
    //   : path;
    return (
        <div style={{ width, height }}>
            {
                <iframe
                    title={url}
                    width='100%'
                    height='100%'
                    src={url}
                >
                </iframe>
            }
        </div>
    )
}
export default PdfJSViewer;