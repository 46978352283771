import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './TransactionsListItem.css';
import { dateToDateFormat, currencyFormat } from '../../utility';

class TransactionsListItem extends React.PureComponent {
  handleListItemClick = () => {
    this.props.goToInvoiceDetails(this.props.record);
  };

  render() {
    const date = dateToDateFormat(this.props.record.transactiondate);
    const paymentAmountText = `${currencyFormat(this.props.record.value)}`;
    const runningbalanceAmountText = `${currencyFormat(this.props.record.runningbalance)}`;
    let statusText = '';
    switch (this.props.record.transactionstatusid) {
      case 1:
        statusText = 'Pending';
        break;
      case 2:
      case 4:
      case 5:
        statusText = 'Cleared';
        break;
      case 3:
        statusText = 'Failed';
        break;
      default:
        break;
    }
    if (this.props.record.transactiontype === 'Invoice') {
      statusText = '';
    // eslint-disable-next-line no-else-return
    }
    return (
      <TableRow key={this.props.record.id}>
        <TableCell>{this.props.record.id}</TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{this.props.record.transactiontype}</TableCell>
        <TableCell>{paymentAmountText}</TableCell>
        <TableCell>{statusText}</TableCell>
        <TableCell>{this.props.record.duedate}</TableCell>
        <TableCell>{runningbalanceAmountText}</TableCell>
      </TableRow>
    );
  }
}

TransactionsListItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired
};
export default TransactionsListItem;
