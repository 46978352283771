import { accountID, appUrl } from '../../../constants';
import { POSTSeaglass } from '../../../Global/Apis/ServerApi';
import * as constant from '../../../constants';
import {
  handleResponseCode,
  showAlert,
  handleException,
} from '../../../utility';

const payment = (props) => {
  console.log('Props : ', props);
  let cardID = '';
  let saveCard = true;
  if (props.selectedCard && props.selectedCard !== '-1') {
    cardID = props.selectedCard;
    saveCard = false;
  }
  let callbackUrlDomain = window.location.origin;
  if (callbackUrlDomain.indexOf('http') === -1) {
    callbackUrlDomain = appUrl;
  }
  // set Parameters for create a session
  const paymentSessionData = {
    type: 'purchase',
    amount: props.amount,
    currency: 'GBP',
    storeCard: saveCard,
    cardId: cardID,
    clienttype: 'moto',
    merchantReference: localStorage.getItem(accountID),
    methods: ['card'],
    callbackUrls: {
      approved: `${callbackUrlDomain}/#/paymentapproved`,
      declined: `${callbackUrlDomain}/#/paymentdeclined`,
      cancelled: `${callbackUrlDomain}/#/paymentdeclined`,
    },
    notificationUrl: constant.notificationUrl,
  };

  // call an api for Create a session of Payment

  const url = `${constant.valdaUrl}windcave/sessions`;
  POSTSeaglass(url, paymentSessionData)
    .then((response) => {
      console.log(response);
      if (handleResponseCode(response)) {
        if (response.response.data) {
          const { data } = response.response;
          const hppIndex = data.links.findIndex((item) => item.rel === 'hpp');
          const urlPayment = data.links[hppIndex].href;
          if (window.cordova) {
            const ref = window.cordova.InAppBrowser.open(
              urlPayment,
              '_blank',
              'location=no'
            );
            ref.addEventListener('loadstart', (event) => {
              if (event.url.indexOf('home') !== -1) {
                ref.close();
                setTimeout(() => {
                  window.location.reload();
                  // this.props.history.push({
                  //   pathname: '/home',
                  //   state: { validated: true },
                  // });
                }, 450);
              }
            });
          } else {
            window.open(urlPayment, '_self');
          }
        }
      } else if (response && response.message) {
        showAlert('Error', response.message);
      }
    })
    .catch((error) => {
      handleException(error);
    });
};

export default payment;
