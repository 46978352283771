import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  accountID,
  seaglassURL,
} from "../../constants";
import UpdatePassword from './UpdatePassword';
import ResetPIN from './ResetPIN';
import CustomAccordion from '../../hoc/CustomAccordion';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import CustomButton from '../../hoc/CustomButton';
import { POSTSeaglass } from "../../Global/Apis/ServerApi";
import {
  logScreenView,
  logEvent,
  handleResponseCode,
  showAlert,
  handleException,
} from "../../utility";


class AccountInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetail: [],
      info: []
    };
  }

  componentDidMount() {
    // Api call for MeterPoint
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}`;
    POSTSeaglass(url)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data) {
            // Handle Address if some field have Null
            const buildingNo = response.response.data.address.buildingnumber;
            const buildingName = response.response.data.address.buildingname;
            const thoroughFare = response.response.data.address.thoroughfare;
            const postTown = response.response.data.address.posttown;
            const postCode = response.response.data.address.postcode;
            // eslint-disable-next-line prefer-destructuring
            const county = response.response.data.address.county;

            const address = [
              buildingNo,
              buildingName,
              thoroughFare,
              postTown,
              postCode,
              county,
            ]
              .filter(Boolean)
              .join(", ");
            console.log("Address :", address);

            const infoArray = [
              {
                title: "Company Details",
                displayFields: [
                  {
                    label: "Company Name",
                    value: response.response.data.name,
                  },
                  {
                    label: "Property Details",
                    value: address,
                  },
                  {
                    label: "Contact Phone Number",
                    value: response.response.data.contact.mobile,
                  },
                ],
              },
              {
                title: "Contact Information",
                displayFields: [
                  {
                    label: "Contact Name",
                    value: `${response.response.data.contact.firstname} ${response.response.data.contact.lastname}`,
                  },
                  {
                    label: "Email",
                    value: response.response.data.contact.email,
                  },
                  {
                    label: "Contact Phone Number",
                    value: response.response.data.contact.mobile,
                  },
                ],
              },
            ];
            this.setState({
              accountDetail: response.response.data,
              info: infoArray,
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }


  handleDeleteAccount = () => {
    logEvent("request_delete_account");
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        "https://www.valdaenergy.com/app-deletion-request",
        "_blank",
        "location=yes"
      );
    } else {
      window.open(
        "https://www.valdaenergy.com/app-deletion-request",
        "_system"
      );
    }
  };

  render() {
    logScreenView("Account Information");
    // const companyDetails = <CustomAccordion key={1} blockTitle={'Company Details'} displayList={[]}></CustomAccordion>
    // const contactDetails = <CustomAccordion></CustomAccordion>
    const accountInfo = this.state.info;
    const accountInfoListitems = accountInfo.map((singleInfoObj, index) => (
      <CustomAccordion
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        blockTitle={singleInfoObj.title}
        displayList={singleInfoObj.displayFields}
      />
    ));
    
    const accountName = localStorage.getItem("companyName");
    const accountIDVal = localStorage.getItem(accountID);

    return (
      <div className="desktopHalfScreenContainer">
        <div>
          <CustomTitlebar
            menuClick={this.props.menuClick}
            showMenuBtn
            titleText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div>
                <div>
                  <span style={{ fontSize: "1.3em" }} className="boldText">
                    {accountName}
                  </span>
                </div>
                <div>{`Account Number: ID ${accountIDVal}`}</div>
              </div>
            }
          />
        </div>
        <div className="scrollingContainer accountInfoScroller">
          <div className="nintyPercentWidth">
            <List component="nav" aria-label="main mailbox folders">
              {accountInfoListitems}
              {/* {companyDetails}
              {contactDetails} */}
            </List>
            <UpdatePassword />
            <ResetPIN />
            <CustomButton
              btnName="Delete Account"
              btnClick={this.handleDeleteAccount}
              showFullWidth
              endBtnIcon={<ArrowForwardIcon />}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

export default connect(mapStateToProps)(AccountInformation);

AccountInformation.propTypes = {
  accountDetail: PropTypes.objectOf(PropTypes.object),
  menuClick: PropTypes.func,
};

AccountInformation.defaultProps = {
  accountDetail: {},
  menuClick: () => false,
};
