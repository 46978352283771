import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import MobileSimpleTitlebar from '../../../hoc/MobileSimpleTitlebar';
import CustomButton from '../../../hoc/CustomButton';
import CustomLink from '../../../hoc/CustomLink';
import { seaglassURL, accountID } from '../../../constants';
import { POSTSeaglass } from '../../../Global/Apis/ServerApi';
import Payment from '../Payments/Payment';
import {
  handleResponseCode,
  showAlert,
  handleException,
  currencyFormat,
  logScreenView,
  logEvent
} from '../../../utility';

class MakePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentAmount: 50,
      selectedCard: "",
      cardsList: [],
      amountList: [
        { amount: 10, estimate: "Estimated 1 week of supply" },
        { amount: 20, estimate: "Estimated 2 weeks of supply" },
        { amount: 50, estimate: "Estimated 4 weeks of supply" },
        { amount: 100, estimate: "Estimated 6 weeks of supply" },
      ],
    };
  }

  componentDidMount() {
    this.handleAmountList();

    // Api call for GetInvoiceDetailByAccountId
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/cards`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data.length) {
            const cards = response.response.data;
            this.setState({
              cardsList: cards,
              selectedCard: cards[cards.length - 1].agentid,
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Handle Amount List as per dailySpend and multiply it by week
  handleAmountList = () => {
    const { accountDetail } = this.props;

    if (
      accountDetail &&
      accountDetail.dailybalance &&
      accountDetail.dailybalance.dailyspend &&
      parseFloat(accountDetail.dailybalance.dailyspend).toString() !== "NaN"
    ) {
      const weeklySpendAmt = accountDetail.dailybalance.dailyspend * 7;
      if (Object.keys(this.props.accountDetail).length !== 0) {
        this.setState({
          paymentAmount: (weeklySpendAmt * 4).toFixed(2),
          amountList: [
            {
              amount: weeklySpendAmt.toFixed(2),
              estimate: "Estimated 1 week of supply",
            },
            {
              amount: (weeklySpendAmt * 2).toFixed(2),
              estimate: "Estimated 2 weeks of supply",
            },
            {
              amount: (weeklySpendAmt * 4).toFixed(2),
              estimate: "Estimated 4 weeks of supply",
            },
            {
              amount: (weeklySpendAmt * 6).toFixed(2),
              estimate: "Estimated 6 weeks of supply",
            },
          ],
        });
      }
    }
  };

  // Handle Amount Click by record
  handleAmountClick = (event) => {
    if (event.target && event.target.getAttribute("record")) {
      this.setState({
        paymentAmount: parseFloat(event.target.getAttribute("record")).toFixed(
          2
        ),
      });
    }
  };

  // Handle Amount Change
  handleAmountChange = (event) => {
    this.setState({
      paymentAmount: event.target.value,
    });
  };

  // Make a Payment button click event
  handleMakePayment = () => {
    logEvent('make_payment');
    const paymentData = {
      amount: parseFloat(this.state.paymentAmount),
      selectedCard: this.state.selectedCard,
    };
    Payment(paymentData);
  };

  // AlterNative Payment Method
  handleAlternativePaymentMethod = () => {
    this.props.showAlternativePayment();
  };

  handleDirectDebitSetup = () => {
    this.props.showDirectDebitSetup();
  };

  // Handle Card Change
  handleCardChange = (event) => {
    this.setState({ selectedCard: event.target.value });
  };

  render() {
    logScreenView("Make payment");
    const cardsList = this.state.cardsList.map((card) => (
      <option key={card.id} value={card.agentid}>
        {`${card.holdername} - ${card.cardnumber}`}
      </option>
    ));

    cardsList.push(
      <option key={-1} value={-1}>
        Use New Card
      </option>
    );

    let listItems;
    if (this.state.amountList) {
      listItems = this.state.amountList.map((amountObj) => {
        let setectedCss = "";

        if (this.state.paymentAmount === amountObj.amount) {
          setectedCss = " selectedAmount";
        }

        return (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          <div
            variant="contained"
            className={`amountBtn ${setectedCss}`}
            onKeyPress={() => {}}
            onClick={this.handleAmountClick}
            role="textbox"
            record={amountObj.amount}
            // tabIndex="0"
            key={amountObj.amount}
          >
            <div
              className="amountText"
              style={{ display: "inline-block" }}
              record={amountObj.amount}
            >
              {`£${currencyFormat(parseFloat(amountObj.amount))}`}
            </div>
            <br />
            <div style={{ display: "inline-block" }} record={amountObj.amount}>
              {amountObj.estimate}
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <MobileSimpleTitlebar
          titleText="MAKE A PAYMENT"
          showMenuBtn
          menuClick={this.props.menuClick}
        />
        <div className="scrollingContainer makePaymentScroller">
          <div className="desktopHalfScreenContainer nintyPercentWidth">
            <h3 className="centerText">SELECT PAYMENT METHOD</h3>
            <div className="paymentMethodArea">
              <div className="leftArea">
                <PaymentOutlinedIcon />
              </div>
              <div className="rightArea">
                Add <b>New Bank Card</b>
                <br />
                or select <b>Previous Card</b>
              </div>
              <TextField
                select
                label=""
                size="small"
                fullWidth
                value={this.state.selectedCard}
                onChange={this.handleCardChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {cardsList}
              </TextField>
            </div>
            <h4 className="centerText smallMarginTop">
              SELECT AN AMOUNT TO TOP UP YOUR ACCOUNT
            </h4>
            <List>{listItems}</List>
            <div className="">Enter other amount</div>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={this.state.paymentAmount}
              onChange={this.handleAmountChange}
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
              fullWidth
              type="number"
              variant="outlined"
              size="small"
              labelWidth={0}
            />
            <CustomButton
              btnName="MAKE A PAYMENT"
              btnClick={this.handleMakePayment}
              showFullWidth
            />
            <div className="centerText marginTop">OR</div>
            <Button
              variant="contained"
              className="signupBtn"
              onClick={this.handleDirectDebitSetup}
              fullWidth="true"
            >
              Set up Direct Debit Payments
            </Button>
            <CustomLink
              linkText="Alternative Methods of Payments"
              linkClick={this.handleAlternativePaymentMethod}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

export default connect(mapStateToProps)(MakePayment);

MakePayment.propTypes = {
  showAlternativePayment: PropTypes.func.isRequired,
  showDirectDebitSetup: PropTypes.func.isRequired,
  menuClick: PropTypes.func,
  accountDetail: PropTypes.objectOf(PropTypes.object),
};

MakePayment.defaultProps = {
  menuClick: () => false,
  accountDetail: {},
};
