import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import { Redirect } from 'react-router-dom';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import PropTypes from 'prop-types';

import SubTitle from '../../hoc/SubTitle';
import CustomLink from '../../hoc/CustomLink';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import { showAlert, removeTokenData, logScreenView } from '../../utility';
import { pinCode } from '../../constants';

class PinNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToLogin: false,
      navigateToHome: false,
      currentCodePosition: 0,
      codeArr: [],
    };
  }

  // Navigate to login screen if user forgot pin code.
  handleDontHavePinCode = () => {
    if (this.props.createPin) {
      localStorage.removeItem(pinCode);
      this.setState({
        navigateToHome: true,
      });
    } else {
      removeTokenData();
      this.setState({
        navigateToLogin: true,
      });
    }
  };

  // Handle click of any number button
  numberClick = (element) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const code = this.state.codeArr;

    // If code length is 5 or more than 5 ignore number click.
    if (code.length >= 5) {
      return;
    }

    // push number to pin code array
    code.push(element.target.innerText);
    this.setState({
      codeArr: code,
      currentCodePosition: code.length,
    });

    if (code.length === 5) {
      // Create pin code and navigate to home screen.
      if (this.props.createPin) {
        localStorage.setItem(pinCode, code.join(''));
        this.setState({
          navigateToHome: true,
        });

        // Show error popup if invalid code used to authenticate login.
      } else if (code.join('') !== localStorage.getItem(pinCode)) {
        showAlert('Error','Invalid code! Please try again');
        this.setState({
          codeArr: [],
          currentCodePosition: 0,
        });

        // Navigate to home screen if user enters valid pin code.
      } else {
        this.setState({
          navigateToHome: true,
        });
      }
    }
  };

  // Delete last entered number for pin code.
  deleteLastDigit = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const code = this.state.codeArr;
    code.pop();
    this.setState({
      codeArr: code,
      currentCodePosition: code.length,
    });
  };

  render() {
    logScreenView("PIN Entry");
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToHome) {
      // redirect to home page with validation
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: { validated: true },
          }}
        />
      );
    }

    const titleText = this.props.createPin
      ? 'To login quickly in the future please create a pin code'
      : 'Enter pin code to quick login';

    const dontHavePin = this.props.createPin
      ? 'I don’t want to set a pin code'
      : 'I don’t remember my pin code';

    const numberBtns = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0].map((value) => (
      <Grid item xs={4} key={`${value}k`}>
        <Fab
          className="greenBg whiteText boldText"
          aria-label="add"
          label={value}
          style={{
            fontSize: '1.5em',
            display: value === '' ? 'none' : '',
          }}
          onClick={this.numberClick}
        >
          {value}
        </Fab>
      </Grid>
    ));

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="Pin Number" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <SubTitle titleText={titleText} />
          <Box
            component="fieldset"
            mb={3}
            borderColor="transparent"
            className="marginTop"
          >
            <Rating
              name="customized-icons"
              readOnly
              value={this.state.currentCodePosition}
              defaultValue={0}
              icon={<FiberManualRecordOutlinedIcon fontSize="inherit" />}
            />
          </Box>
          <Grid container spacing={2} className="centerText smallMarginTop">
            {numberBtns}
            <Grid item xs={4} style={{ fontSize: '1.5em', paddingTop: '16px' }}>
              <BackspaceIcon
                style={{ fontSize: '2em' }}
                onClick={this.deleteLastDigit}
              />
            </Grid>
          </Grid>
          <div
            className="marginTop"
            style={{ display: this.props.createPin ? 'none' : '' }}
          >
            <CustomLink
              linkText={dontHavePin}
              linkClick={this.handleDontHavePinCode}
            />
          </div>
        </Grid>
      </div>
    );
  }
}

export default PinNumber;

PinNumber.propTypes = {
  createPin: PropTypes.bool,
};

PinNumber.defaultProps = {
  createPin: false,
};
