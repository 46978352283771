import React from 'react';
import PropTypes from 'prop-types';

class SitesPricingListItem extends React.PureComponent {
  render() {
    console.log('this.props.record: ', this.props.record);
    const linedescription = this.props.record[0].linedescription;
    const price = this.props.record[0].price;
    return (
      <div>
        <div className="boldText smallMarginTop">{linedescription}</div>
        <div>{price}</div>
      </div>
    );
  }
}

SitesPricingListItem.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SitesPricingListItem;
