import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import { currencyFormat } from '../utility';

class HHBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.chartData,
      options: {
        chart: {
          height: 300,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        xaxis: {
          type: 'numeric',
          labels: {
            show: true,
            style: {
              fontSize: "7px",
            },
            formatter(val) {
              return val.toFixed(0);
            },
          },
          title: {
            text: "Half Hour",
          },
        },
        yaxis: {
          title: {
            text: "KWh",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: (value) => `${currencyFormat(value)}`,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.series.data !== nextProps.chartData) {
      return {
        series: nextProps.chartData,
        options: {
          ...prevState.options,
        },
      };
    }
    return null;
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="line"
        height={300}
        width={"98%"}
      />
    );
  }
}

export default HHBarChart;

HHBarChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
};

HHBarChart.defaultProps = {
  chartData: [],
};
