import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import { currencyFormat } from '../utility';

class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Amount (Actual)",
          data: this.props.chartDataActuals,
        },
        {
          name: "Amount (Estimate)",
          data: this.props.chartDataEstimates,
        },
      ],
      options: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        colors: this.props.lineColor,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "7px",
            },
          },
          title: {
            text: this.props.xAxisText,
          },
        },
        yaxis: {
          title: {
            text: "KWh",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            //formatter: (value) => `£${currencyFormat(value)}`,
            formatter: (value) => `${currencyFormat(value)}`,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.series.data !== nextProps.chartDataActuals ||
      prevState.series.data !== nextProps.chartDataEstimates
    ) {
      const xAxisLabel = [];
      nextProps.chartDataActuals.forEach((element) => {
        xAxisLabel.push(element.x);
      });
      return {
        series: [
          {
            name: "Amount (Actual)",
            data: nextProps.chartDataActuals,
          },
          {
            name: "Amount (Estimate)",
            data: nextProps.chartDataEstimates,
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            categories: xAxisLabel,
            labels: {
              show: true,
              style: {
                fontSize: "7px",
              },
            },
            title: {
              text: nextProps.xAxisText,
            },
          },
        },
      };
    }
    return null;
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={300}
      />
    );
  }
}

export default LineChart;

LineChart.propTypes = {
  lineColor: PropTypes.arrayOf(PropTypes.string),
  chartDataActuals: PropTypes.arrayOf(PropTypes.object),
  chartDataEstimates: PropTypes.arrayOf(PropTypes.object),
  xAxisText: PropTypes.arrayOf(PropTypes.string),
};

LineChart.defaultProps = {
  lineColor: ["#0A7C41", "#BFCEC6"],
  chartDataActuals: [],
  chartDataEstimates: [],
  xAxisText: ''
};
