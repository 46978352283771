import React, { Component } from 'react';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import { termsAndConditionsText } from './TermsText';
import SubTitle from '../../hoc/SubTitle';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import { logScreenView } from "../../utility";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    logScreenView("Terms and conditions");
    return (
      <div>
        <MobileSimpleTitlebar
          titleText="TERMS & CONDITIONS"
          showMenuBtn
          menuClick={this.props.menuClick}
        />
        <div className="dontShowOnMobile">
          <SubTitle titleText="TERMS & CONDITIONS" />
        </div>
        <div className="scrollingContainer faqScroller">
          <div className="nintyPercentWidth">
            <List component="nav" aria-label="main mailbox folders">
              {termsAndConditionsText}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;

TermsAndConditions.propTypes = {
  menuClick: PropTypes.func,
};

TermsAndConditions.defaultProps = {
  menuClick: () => false,
};
