import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PaymentListItem from '../PaymentListItem/PaymentListItem';
import {
  seaglassURL,
  accountID,
  accountBalance,
  debitCreditText,
} from '../../constants';
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import {
  handleResponseCode,
  showAlert,
  handleException,
  logScreenView
} from '../../utility';
import CustomButton from '../../hoc/CustomButton';

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImportantInfo: false,
      PaymentList: [],
    };
  }

  componentDidMount() {
    // Api call for GetInvoiceDetailByAccountId

    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/payments`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data) {
            this.setState({
              PaymentList: response.response.data,
            });
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  handleMakePayment = () => {
    this.props.makePaymentClick(true);
  };

  render() {
    logScreenView("Payment list");
    let listItems;
    if (this.state.PaymentList) {
      // eslint-disable-next-line arrow-body-style
      listItems = this.state.PaymentList.map((item) => {
        return (
          <PaymentListItem
            record={item}
            key={item.id}
          />
        );
      });
    }

    const accountBalanceValue = localStorage.getItem(accountBalance);
    const debitCreditTextValue = localStorage.getItem(debitCreditText);

    return (
      <div className="desktopHalfScreenContainer">
        <CustomTitlebar
          menuClick={this.props.menuClick}
          showMenuBtn
          titleText={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <div>
                <span className="bigText boldText">{`£${accountBalanceValue}  `}</span>
                <span>{debitCreditTextValue}</span>
              </div>
              <div>Account Balance</div>
            </div>
          }
        />
        <div className="nintyPercentWidth">
          <CustomButton
            btnName="MAKE A PAYMENT"
            btnClick={this.handleMakePayment}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </div>
        <div className="scrollingContainer invoiceScroller">
          <div>
            <h3 className="centerText">PAYMENTS</h3>
            <TableContainer className="invoice-table nintyPercentWidth curvyCorners whiteBg marginTop">
              <Table aria-label="simple table invoice-table" className="">
                <TableHead>
                  <TableRow>
                    <TableCell>DATE</TableCell>
                    <TableCell>METHOD</TableCell>
                    <TableCell>AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listItems}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentList;

PaymentList.propTypes = {
  menuClick: PropTypes.func,
  makePaymentClick: PropTypes.func.isRequired,
  goToInvoiceDetails: PropTypes.func.isRequired,
};

PaymentList.defaultProps = {
  menuClick: () => false,
};
