import React, { Component } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

class SimpleAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Accordion
        className="customAccordianContainer marginTop"
        defaultExpanded={this.props.sholdExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
          className="accordianTitle"
        >
          <Typography>{this.props.blockTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div component="nav" aria-label="main mailbox folders" fullwidth>
              {this.props.displayText}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default SimpleAccordion;
SimpleAccordion.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  displayText: PropTypes.arrayOf(PropTypes.object).isRequired,
  sholdExpanded: PropTypes.bool,
};

SimpleAccordion.defaultProps = {
  sholdExpanded: false,
};
