import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DisplayField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="displayFieldLabel">{this.props.label}</div>
        <div className="displayFieldValue">{this.props.value}</div>
      </div>
    );
  }
}

export default DisplayField;
DisplayField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

DisplayField.defaultProps = {
  label: '',
  value: '',
};
