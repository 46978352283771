import React from 'react';

// FAQ Text for FAQ
export const paymentsAndInvoices = [
  {
    question: 'How do I know when my next payment will be taken?',
    answer: (
      <div>
        <div>
          If your smart meter is not yet installed and/or you do not have a
          smart enabled meter, then payment will be taken every 30 days on a
          rolling basis.
        </div>
        <br />
        <div>
          Once your smart meter has been installed, you will switch to threshold
          payments, which means about one month of payment is taken
          automatically whenever your balance runs low – so you never run out.
          If you wish, at this point you can switch to self-managed payments,
          allowing you to decide when and how much you top up your energy
          balance. To switch to self-managed payments please contact us on 0330
          3904510, available 09:00 - 17:00, Monday to Friday and our team will be
          happy to help.
        </div>
      </div>
    ),
  },
  {
    question: 'How do I make a card payment?',
    answer: (
      <div>
        Please click the “Payments” icon on the app, or the “Payments &
        Invoices” option within the menu bar. Here you will find a button
        saying, “Make payments”, please click this button and follow the
        instructions to make a card payment.
      </div>
    ),
  },
  {
    question: 'How do I change the bank card used on my account?',
    answer: (
      <div>
        Please click the “Payments” icon on the app, or the “Payments &
        Invoices” option within the menu bar. Here you will find a button
        saying, “Make payments”, please click this button, choose “Use New Card”
        from “Select payment method dropdown” and follow the instructions to
        make a card payment.
      </div>
    ),
  },
  {
    question:
      'How can I set up a different way to pay e.g. Direct Debit, or standing order?',
    answer: (
      <div>
        Please call our team on 0330 390 4510, available 09:00 - 17:00, Monday to
        Friday and they will happily set you up on a different payment method.
      </div>
    ),
  },
  {
    question: 'How can I see my past payments?',
    answer: (
      <div>
        To view invoices, please click the “Payments” icon on the app, or the
        “Payments & Invoices” option within the menu bar. To view a graph of
        past payments, please view your home page.
      </div>
    ),
  },
  {
    question: 'Can I control when and how much I pay?',
    answer: (
      <div>
        <div>
          If your smart meter is not yet installed and/or you do not have a
          smart enabled meter, then payment will be taken every 30 days on a
          rolling basis.
        </div>
        <br />
        <div>
          Once your smart meter has been installed, you will switch to threshold
          payments, which means about one month of payment is taken
          automatically whenever your balance runs low – so you never run out.
          If you wish, at this point you can switch to self-managed payments,
          allowing you to control when and how much you pay. To switch to
          self-managed payments, please contact us now, and we’ll be happy to
          make the change on 0330 390 4510, available 09:00 - 17:00, Monday to Friday.
        </div>
      </div>
    ),
  },
  {
    question: 'What is my current balance on my account?',
    answer: (
      <div>
        Displayed at the top of the home page of the app is your current
        balance.
      </div>
    ),
  },
  {
    question: 'How will I know if my balance is running low?',
    answer: (
      <div>
        Displayed at the top of the home page of your app, is your current
        balance, plus how many estimated days of energy credit you have left –
        so you will always know if you are running low. We will also send you
        automatic alerts by SMS and email when you have about 14 days, 7 days and
        3 days left, to ensure you never run out of power.
      </div>
    ),
  },
  {
    question: 'Where can I view my monthly invoices?',
    answer: (
      <div>
        Invoices will be emailed out to you every month. You can also download
        current and previous invoices from your app, by viewing the “Payments &
        Invoices” option within the menu bar.
      </div>
    ),
  },
  {
    question: 'Does nonpayment mean disconnection?',
    answer: (
      <div>
        <div>
          Disconnection is only ever used as a last resort where customers do
          not pay. Before disconnection we will try to understand your
          circumstances and set up an affordable payment plan. Advice agencies
          usually recommend that you treat gas and electricity bills as a
          priority debt, so as soon as you realise your business is having
          difficulty paying its bills, just get in touch with us.
        </div>
        <br />
        <div>
          If we must force entry to your property to access the meter, we may
          take the case to court to obtain a warrant to enter your business.
          Sometimes it is not necessary to access the meter to disconnect it so
          we may not always obtain a warrant.
        </div>
      </div>
    ),
  },
  {
    question: 'I’m struggling to pay, what do I do?',
    answer: (
      <div>
        <div>
          Anyone can have trouble paying their energy bills now and then. If you
          are struggling with your bills, please get in touch so we can help.
        </div>
        <br />
        <div>
          We understand that there may be times when your business finances are
          under pressure. We are committed to treating all our customers fairly,
          so if you are struggling to pay your business energy bills, we want to
          help you get back on track.
        </div>
        <br />
        <div>
          If you are finding payments difficult, please get in touch so we can
          talk it through with you.
        </div>
      </div>
    ),
  },
];

export const smartMeters = [
  {
    question: 'When will my smart meter be installed?',
    answer: (
      <div>
        If you need a smart meter to be installed, we aim for all installs to be
        complete within 6 weeks of your energy start date. If you do not already
        have a date for your installation, please contact us on 0330 390 4510,
        available 09:00 - 17:00, Monday to Friday to make an appointment.
      </div>
    ),
  },
  {
    question: 'Who will install my smart meter?',
    answer: (
      <div>
        An accredited meter installer working on behalf of one of Valda Energy's metering partners will complete the installation. They will also be able to present you with identification upon arrival.
      </div>
    ),
  },
  {
    question:
      'What do I need to do to prepare for my smart meter installation?',
    answer: (
      <div>
        Our metering installation partners will contact you prior to install and ask you a list of questions and be able to answer any queries you have.
      </div>
    ),
  },
  {
    question: 'Is there an issue with my smart meter?',
    answer: (
      <div>
        <div>
          If you believe your meter is faulty, please contact us immediately so
          we can try to resolve the issue. We may need to send out an engineer
          and investigate the problem.
        </div>
        <br />
        <div>
          If you think your gas meter is faulty, you might have a gas leak. If
          you can smell gas you should leave the premises and call the National
          Gas Emergencies number immediately on 0800 111 999.
        </div>
      </div>
    ),
  },
  {
    question: 'Do I need to provide meter readings?',
    answer: (
      <div>
        If you are waiting for your new smart meter to be installed, we
        recommend you send meter reads as often as possible to ensure your
        billing is as accurate as possible.
      </div>
    ),
  },
];

export const accountManagement = [
  {
    question: 'How do I know how many days of energy I have left?',
    answer: (
      <div>
        At the top of the home page of your app details how many estimated days
        of energy credit you have left.
      </div>
    ),
  },
  {
    question: 'Where can I see how much energy I’ve used?',
    answer: (
      <div>
        Please click on the Usage icon in your app, to view historical usage
        data.
      </div>
    ),
  },
  {
    question: 'Why does my energy usage graph have a dotted line?',
    answer: (
      <div>
        Dotted lines indicate non-smart meter data usage, so is likely to be
        estimated. Solid lines indicate smart meter data, so it is accurate.
      </div>
    ),
  },
  {
    question: 'Can I change the password on my account?',
    answer: (
      <div>
        Yes, please click on the Account icon in your app, you will find a page
        which details all of your account details. Scroll to the bottom of the
        page to find the “Update Password” button, please click on this button
        and follow the instructions.
      </div>
    ),
  },
  {
    question: 'Where can I see my contract and tariff details?',
    answer: (
      <div>
        Please click on the Account icon in your app, you will find a page which
        details all of your account details, including your tariff.
      </div>
    ),
  },
  {
    question: 'Where can I see a list of all my sites?',
    answer: (
      <div>
        Please click on the Account icon in your app, you will find a page which
        details all of your account details, including all your sites.
      </div>
    ),
  },
  {
    question: 'How do I change my contact details?',
    answer: (
      <div>
        Please call our Customer Care team on 0330 390 4510, available 09:00 - 17:00,
        Monday to Friday and they will be happy to help you.
      </div>
    ),
  },
  {
    question: 'What should I do if I want to move premises?',
    answer: (
      <div>
        Please call our Customer Care team on 0330 390 4510, available 09:00 - 17:00,
        Monday to Friday and they will be happy to help you.
      </div>
    ),
  },
  {
    question: 'I want to renew my contract, what should I do?',
    answer: (
      <div>
        Please call our friendly team on 0330 124 2023, available 09:00 - 17:00,
        Monday to Friday and they will be happy to help you.
      </div>
    ),
  },
];

export const emergenciesReconnections = [
  {
    question:
      'I don’t understand how to use my app or make a payment, who can I talk to?',
    answer: (
      <div>
        Please call our friendly Customer Care team on 0330 390 4510, available
        09:00 - 17:00, Monday to Friday and they will be happy to help you.
      </div>
    ),
  },
  {
    question: 'How can I get my energy reconnected?',
    answer: (
      <div>
        <div>
          If a meter has been disconnected and you wish to arrange reconnection,
          you may need to pay a non-refundable connection fee before this can
          happen. If we are concerned about your ability to pay future bills, we
          may also ask you to provide a refundable security deposit as a
          condition of re-connecting the energy supply to your business.
        </div>
        <br />
        <div>
          After we have received payment, we will ask the engineers in your area
          to book the reconnection. The engineers are not directly employed by
          Valda Energy, so we are not able to influence their availability.
          Depending on the type of meter you have at your property, this could
          take between 48hrs and 12 days and you may need to be at the property
          to allow them access to the meter.
        </div>
      </div>
    ),
  },
  {
    question: 'What happens if there is a power cut in my area',
    answer: (
      <div>
        <div>
          Call 105 (free and available 24 hours a day) to reach your local
          network operator who can give you help and advice. These companies
          manage and maintain the cables, wires and substations that provide
          your business with electricity, so if you experience a power outage,
          these are the people to contact regardless of your energy provider.
        </div>
        <br />
        <div>For more information, please visit www.powercut105.com.</div>
      </div>
    ),
  },
  {
    question: 'What should I do if I smell gas?',
    answer: (
      <div>
        <div>
          To report a gas emergency, or if a pipeline is struck (even if no gas
          leak has occurred) call the National Gas Emergency number on 0800 111
          999 (available 24 hours a day).
        </div>
        <br />
        <div>
          <div>In the event of an emergency:</div>
          <ul>
            <li>Do not smoke or light matches</li>
            <li>Do not turn electrical switches on or off</li>
            <li>Open doors and windows</li>
            <li>
              If safe to do so, turn off the meter at the control handle unless
              the meter is in the cellar
            </li>
          </ul>
        </div>
      </div>
    ),
  },
];
