import React, { Component } from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TransactionsListItem from "./TransactionsListItem";
import {
  seaglassURL,
  accountID,
  accountBalance,
  debitCreditText,
} from "../../constants";
import { POSTSeaglass } from "../../Global/Apis/ServerApi";
import CustomTitlebar from "../../hoc/CustomTitlebar";
import {
  handleResponseCode,
  showAlert,
  handleException,
  logScreenView,
} from "../../utility";

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImportantInfo: false,
      TransactionsList: [],
    };
  }

  componentDidMount() {
    // Api call for GetInvoiceDetailByAccountId

    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/transactions`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data) {
            this.setState({
              TransactionsList: response.response.data,
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  handleMakePayment = () => {
    this.props.makePaymentClick(true);
  };

  render() {
    logScreenView("Transactions list");
    let listItems;
    if (this.state.TransactionsList) {
      // eslint-disable-next-line arrow-body-style
      listItems = this.state.TransactionsList.map((item) => {
        return <TransactionsListItem record={item} key={item.id} />;
      });
    }

    const accountBalanceValue = localStorage.getItem(accountBalance);
    const debitCreditTextValue = localStorage.getItem(debitCreditText);

    return (
      <div className="desktopFullScreenContainer">
        <CustomTitlebar
          menuClick={this.props.menuClick}
          showMenuBtn
          titleText={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <div>
                <span className="bigText boldText">{`£${accountBalanceValue}  `}</span>
                <span>{debitCreditTextValue}</span>
              </div>
              <div>Transactions</div>
            </div>
          }
        />
        <div className="scrollingContainer invoiceScroller">
          <div>
            <TableContainer className="invoice-table nintyPercentWidth curvyCorners whiteBg marginTop">
              <Table aria-label="simple table invoice-table" className="">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Trans Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Running Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{listItems}</TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;

Transactions.propTypes = {
  menuClick: PropTypes.func
};

Transactions.defaultProps = {
  menuClick: () => false,
};
