import React, { Component } from 'react';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import {
  paymentsAndInvoices,
  smartMeters,
  accountManagement,
  emergenciesReconnections,
} from './FaqText';
import SubTitle from '../../hoc/SubTitle';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import SimpleAccordion from '../../hoc/SimpleAccordion';
import { logScreenView } from "../../utility";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    logScreenView("FAQs");
    const faqData = [
      {
        id: 1,
        data: paymentsAndInvoices,
        title: 'Payments and invoices',
      },
      {
        id: 2,
        data: smartMeters,
        title: 'Smart meters and meter reads',
      },
      {
        id: 3,
        data: accountManagement,
        title: 'Account management',
      },
      {
        id: 4,
        data: emergenciesReconnections,
        title: 'Emergencies and reconnections',
      },
    ];

    const faqElement = faqData.map((faq, indexMain) => {
      const faqSubList = faq.data.map((faqObj, index) => (
        <SimpleAccordion
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          blockTitle={faqObj.question}
          displayText={faqObj.answer}
          sholdExpanded={index === 0 && indexMain === 0}
        />
      ));

      return (
        <div>
          <div
            className="marginTop centerText boldText"
            style={{ fontSize: '1.1em' }}
          >
            {faq.title}
          </div>
          <List component="nav" aria-label="main mailbox folders">
            {faqSubList}
          </List>
        </div>
      );
    });

    return (
      <div>
        <MobileSimpleTitlebar
          titleText="FREQUENTLY ASKED QUESTIONS"
          showMenuBtn
          menuClick={this.props.menuClick}
        />
        <div className="dontShowOnMobile">
          <SubTitle titleText="FREQUENTLY ASKED QUESTIONS" />
        </div>
        <div className="scrollingContainer faqScroller">
          <div className="nintyPercentWidth">
            {faqElement}
            <div
              className="marginTop boldText"
              style={{ fontSize: '1.2em', textAlign: 'justify' }}
            >
              If you need further help, please view our more comprehensive FAQs
              found on our website. Or, please call us on 0330 390 4510, or use
              the LiveChat function on our website and we’ll be happy to help.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;

FAQ.propTypes = {
  menuClick: PropTypes.func,
};

FAQ.defaultProps = {
  menuClick: () => false,
};
