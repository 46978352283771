import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';

class NoConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToHome: false,
    };
  }

  // when connect to internet again so app redirect to Home page
  handleReturnHome = () => {
    this.setState({
      navigateToHome: true,
    });
  };

  render() {
    if (this.state.navigateToHome) {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <div className="centerText marginTop">
            Sorry - It looks like we were unable to connect.
          </div>
          <div className="centerText">
            Please check your internet connection or retry.
          </div>

          <CustomButton
            btnName="RETRY"
            btnClick={this.handleReturnHome}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
        </Grid>
      </div>
    );
  }
}

export default NoConnection;
