import React from 'react';

export const termsAndConditionsText = (
  <div>
    <h1>
      <strong>App Licence Terms</strong>
    </h1>
    <p>
      <strong>PLEASE READ THESE LICENCE TERMS CAREFULLY</strong>
    </p>
    <p>
      BY DOWNLOADING THE APP YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE TO
      THESE TERMS DO NOT DOWNLOAD THE APP.
    </p>
    <p>
      You must be 18 or over to accept these terms, download and install the
      App.
    </p>
    <h3>Who we are and what this agreement does</h3>
    <p>
      We – Valda Energy Limited (company number 11212563) of Unit 11, Talisman
      Business Centre, Talisman Road, Bicester, OX26 6HR – license you to use:
    </p>
    <ul>
      <li>
        {' '}
        the Valda Energy application software and the data supplied with the
        software, including any updates or supplements to it (
        <strong>App</strong>);
      </li>
      <li>
        {' '}
        the related online documentation (<strong>Documentation</strong>); and
      </li>
      <li>
        {' '}
        the services you connect to via the App and the content we provide to
        you through it (<strong>Services</strong>),as permitted in these terms.
      </li>
    </ul>
    <h2>Your privacy</h2>
    <p>
      Under data protection legislation, we are required to provide you with
      certain information about who we are, how we process your personal data
      and for what purposes, and your rights in relation to your personal data
      and how to exercise them. This information is provided in our{' '}
      <a href="https://www.valdaenergy.com/privacy" target="_system">
        privacy notice
      </a>
      , and it is important that you read that information.
    </p>
    <p>
      Please be aware that internet transmissions are never completely private
      or secure and that any message or information you send using the App or
      any Services may be read or intercepted by others, even if there is a
      special notice that a particular transmission is encrypted.
    </p>
    <p>
      By using the App or any of the Services, you agree to us collecting and
      using technical information about the devices you use the App on and
      related software, hardware and peripherals to improve our products and to
      provide any Services to you.
    </p>
    <h2>Additional terms</h2>
    <p>
      The App, and the Services provided through it, will help you to manage
      your customer account with us. However, your supply of energy, or any
      other contract you have entered into with us, will be governed by the{' '}
      <a
        href="https://www.valdaenergy.com/terms-and-conditions"
        target="_system"
      >
        terms and conditions
      </a>{' '}
      for the relevant product or service. These terms and conditions may affect
      your use of the Services.
    </p>
    <p>
      The ways in which you can use the App and Documentation may also be
      controlled by the app store’s rules and policies.
    </p>
    <h2>Support for the App and how to tell us about problems</h2>
    <p>
      If you want to learn more about the App or the Services, or have any
      problems using them, please take a look at the FAQs within the App or the
      support resources on our website at{' '}
      <u>www.valdaenergy.com/why-valda/help-and-faqs</u>.
    </p>
    <p>
      If you think the App or the Services are faulty, or wish to contact us for
      any other reason, please contact our Customer Service team on 0330 390
      4510 or through our website at{' '}
      <a href="http://www.valdaenergy.com/contact">
        www.valdaenergy.com/contact
      </a>
      .
    </p>
    <p>
      If we have to contact you, we will do so by email or by SMS, using the
      contact details you have provided to us.
    </p>
    <h2>How you may use the App</h2>
    <p>In return for your agreeing to comply with these terms you may:</p>
    <ul>
      <li>
        {' '}
        download or stream a copy of the App onto any compatible device and
        view, use and display the App and the Services on such devices for the
        purposes of managing your customer account only;
      </li>
      <li>
        {' '}
        use any Documentation to support your permitted use of the App and the
        Services;
      </li>
      <li>
        {' '}
        provided you comply with these terms, make copies of the App and the
        Documentation for back-up purposes; and
      </li>
      <li>
        {' '}
        receive and use any free supplementary software code or update of the
        App incorporating “patches” and corrections of errors as we may provide
        to you.
      </li>
    </ul>
    <p>
      We are giving you personally the right to use the App and the Services as
      set out above. You may not otherwise transfer the App or the Service to
      someone else, whether for money, for anything else or for free. If you
      sell any device on which the App is installed, you must remove the App
      from it.
    </p>
    <p>
      If you download or stream the App onto any device not owned by you, you
      must have the owner’s permission to do so. You will be responsible for
      complying with these terms, whether or not you own the device.
    </p>
    <p>
      You are responsible for keeping any username, password or other
      information used for signing into the App confidential. You must not
      disclose this information to any third party. You are responsible for all
      activities that occur using such information and the results of such use.
      If you know or suspect that anyone other than you knows your details for
      signing-in, or if you believe that there has been unauthorised use of this
      information, you must contact our Customer Service team promptly on 0330
      390 4510 or through our website at{' '}
      <a href="http://www.valdaenergy.com/contact">
        www.valdaenergy.com/contact
      </a>
      .
    </p>
    <p>
      We only supply our products and services to business customers in Great
      Britain. The App and the Services are therefore directed to businesses in
      Great Britain.
    </p>
    <h2>Changes to these terms</h2>
    <p>
      We may change these terms at any time. For example, to reflect changes in
      law or best practice or to deal with additional features which we
      introduce.
    </p>
    <p>
      We will normally notify you of any change by sending you an email or SMS
      with details of the change, but we may notify you of a change when you
      next start the App.
    </p>
    <p>
      If you do not accept the notified changes you will not be permitted to
      continue to use the App and the Services.
    </p>
    <h2>Updates to the App and changes to the Services</h2>
    <p>
      From time to time we may automatically update the App and change the
      Services to improve performance, enhance functionality, reflect changes to
      associated contracts and operating systems or address security issues.
      Alternatively, we may ask you to update the App for these reasons.
    </p>
    <p>
      If you choose not to install such updates, or if you opt out of automatic
      updates, you may not be able to continue using the App and the Services.
    </p>
    <h2>We do not provide you with advice</h2>
    <p>
      All information provided to you through the App is provided for general
      information purposes only. It does not amount to advice on which you
      should rely and you agree to make decisions, based on your own judgment
      and any advice from such advisors as you deem necessary, before taking, or
      refraining from, any action on the basis of information obtained from the
      App or the Services.
    </p>
    <p>
      Although we make reasonable efforts to update the information provided by
      the App and the Services, we make no representations, warranties or
      guarantees, whether express or implied, that such information is accurate,
      complete or up to date.
    </p>
    <h2>We are not responsible for other websites you link to</h2>
    <p>
      The App or any Services may contain links to other independent websites
      which are not provided by us. Such independent sites are not under our
      control, and we are not responsible for and have not checked and approved
      their content or their privacy policies (if any).
    </p>
    <p>
      You will need to make your own independent judgement about whether to use
      any such independent sites, including whether to buy or use any products
      or services offered by them.
    </p>
    <h2>Licence restrictions</h2>
    <p>You agree that you will:</p>
    <ul>
      <li>
        {' '}
        not rent, lease, sub-license, loan, provide, or otherwise make
        available, the App or the Services in any form, in whole or in part to
        any person without prior written consent from us;
      </li>
      <li>
        {' '}
        not copy the App, Documentation or Services, except as part of the
        normal use of the App or where it is necessary for the purpose of
        back-up or operational security;
      </li>
      <li>
        {' '}
        not translate, merge, adapt, vary, alter or modify, the whole or any
        part of the App, Documentation or Services nor permit the App or the
        Services or any part of them to be combined with, or become incorporated
        in, any other programs, except as necessary to use the App and the
        Services on devices as permitted in these terms;
      </li>
      <li>
        not disassemble, de-compile, reverse engineer or create derivative works
        based on the whole or any part of the App or the Services nor attempt to
        do any such things, except to the extent that (by virtue of sections 50B
        and 296A of the Copyright, Designs and Patents Act 1988) such actions
        cannot be prohibited because they are necessary to decompile the App to
        obtain the information necessary to create an independent program that
        can be operated with the App or with another program (
        <strong>Permitted Objective</strong>), and provided that the information
        obtained by you during such activities:
        <ul>
          <li>
            {' '}
            is not disclosed or communicated without the licensor’s prior
            written consent to any third party to whom it is not necessary to
            disclose or communicate it in order to achieve the Permitted
            Objective;
          </li>
          <li>
            {' '}
            is not used to create any software that is substantially similar in
            its expression to the App;
          </li>
          <li> is kept secure; and</li>
          <li> is used only for the Permitted Objective; and</li>
        </ul>
      </li>
      <li>
        {' '}
        comply with all applicable technology control or export laws and
        regulations that apply to the technology used or supported by the App or
        any Services.
      </li>
    </ul>
    <h2>Acceptable use restrictions</h2>
    <p>You must:</p>
    <ul>
      <li>
        {' '}
        not use the App or any Services in any unlawful manner, for any unlawful
        purpose, or in any manner inconsistent with these terms, or act
        fraudulently or maliciously. For example, by hacking into or inserting
        malicious code, such as viruses, or harmful data, into the App, any
        Services or any operating system;
      </li>
      <li>
        {' '}
        not infringe our intellectual property rights or those of any third
        party in relation to your use of the App or any Services (to the extent
        that such use is not licensed by these terms);
      </li>
      <li>
        {' '}
        not transmit any material that is defamatory, offensive or otherwise
        objectionable in relation to your use of the App or any Services;
      </li>
      <li>
        {' '}
        not use the App or any Services in a way that could damage, disable,
        overburden, impair or compromise our systems or security or interfere
        with other users; and
      </li>
      <li>
        {' '}
        not collect or harvest any information or data from any Services or our
        systems or attempt to decipher any transmissions to or from the servers
        running any Services.
      </li>
    </ul>
    <h2>Intellectual Property Rights</h2>
    <p>
      All intellectual property rights in the App, the Documentation and the
      Services throughout the world belong to us (or our licensors) and the
      rights in the App and the Services are licensed (not sold) to you. You
      have no intellectual property rights in, or to, the App, the Documentation
      or the Services other than the right to use them in accordance with these
      terms.
    </p>
    <p>
      “VALDA ENERGY” is a UK registered trade mark of Valda Energy Group Limited
      (company number 11852539). You are not permitted to use this mark without
      our approval, unless it forms part of the material you are using as
      permitted under these terms.
    </p>
    <h2>Our responsibility for loss or damage suffered by you</h2>
    <p>
      You acknowledge that the App and the Services have not been developed to
      meet your individual requirements.
    </p>
    <p>
      We do not exclude or limit in any way our liability to you where it would
      be unlawful to do so. This includes liability for death or personal injury
      caused by our negligence or the negligence of our employees, agents or
      subcontractors and for fraud or fraudulent misrepresentation.
    </p>
    <p>To the fullest extent permitted by law:</p>
    <ul>
      <li>
        {' '}
        we exclude all implied conditions, warranties, representations or other
        terms that may apply to the App and the Services; and
      </li>
      <li>
        {' '}
        we will not be liable to you for any loss or damage, whether in
        contract, tort (including negligence), breach of statutory duty or
        otherwise, even if foreseeable, arising under or in connection with: (a)
        use of, or inability to use, the App or the Services; or (b) use of or
        reliance on any content we provide to you through the App. In
        particular, we will not be liable for: (a) loss of profits, sales,
        business, or revenue; (b) business interruption; (c) loss of anticipated
        savings; (d) loss of business opportunity, goodwill or reputation; (e)
        loss of use or corruption of software, data or information; (f) wasted
        management or office time; or (g) any indirect or consequential loss or
        damage.
      </li>
    </ul>
    <p>
      If our provision of the Services or support for the App or the Services is
      delayed by an event outside our control, then we will not be liable for
      delays caused by the event. We will contact you as soon as possible to let
      you know of the delay and we will take reasonable steps to minimise the
      effect of the delay.
    </p>
    <p>
      Different limitations and exclusions may apply to liability arising under
      any other contract we have with you, which will be set out in our relevant
      terms and conditions.
    </p>
    <h2>We may end your rights to use the App and the Services</h2>
    <p>
      The App and the Services are made available free of charge. We do not
      guarantee that the App or the Services will always be available or be
      uninterrupted. We may suspend or withdraw or restrict the availability of
      all or any part of the App or the Services for business and operational
      reasons. We may also end your rights to use the App and Services at any
      time. We will try to give you reasonable notice in each case, but this may
      not always be possible.
    </p>
    <p>If we end your rights to use the App and Services:</p>
    <ul>
      <li>
        {' '}
        you must stop all activities authorised by these terms, including your
        use of the App and any Services;
      </li>
      <li>
        {' '}
        you must delete or remove the App from all devices in your possession
        and immediately destroy all copies of the App which you have and confirm
        to us that you have done this; and
      </li>
      <li>
        {' '}
        we may remotely access your devices and remove the App from them and
        cease providing you with access to the Services.
      </li>
    </ul>
    <h2>Other important information</h2>
    <p>
      We may transfer our rights and obligations under these terms to another
      organisation. We will always tell you in writing if this happens and we
      will ensure that the transfer will not affect your rights under the
      agreement.
    </p>
    <p>
      You may only transfer your rights or your obligations under these terms to
      another person if we agree in writing.
    </p>
    <p>
      This agreement is between you and us. No other person has any rights to
      enforce any of its terms.
    </p>
    <p>
      Each paragraph of these terms operates separately. If any court or
      relevant authority decides that any of them are unlawful, the remaining
      paragraphs will continue in full force and effect.
    </p>
    <p>
      Even if we delay in enforcing this agreement, we can still enforce it
      later. If we do not insist immediately that you do anything you are
      required to do under these terms, or if we delay in taking steps against
      you in respect of your breaking this agreement, that will not mean that
      you do not have to do those things and it will not prevent us taking steps
      against you at a later date.
    </p>
    <p>
      These terms are governed by English law. You and we agree to bring all
      disputes arising out of or in connect with these terms to the English
      courts.
    </p>
  </div>
);

export default termsAndConditionsText;
